import Api from '../../../store/api';
import { STAFF_BASE } from '../../../store/endpoint';
import { update_staffs, add_single_staff, remove_single_staff, update_single_staff, update_single_staff_status, update_staff_loader } from '../store/slice';
import Swal from 'sweetalert2';

export const fetch_staffs = (history, dispatch, page_no, prev_data, search = '', loader = true) => {
    let api_url = `${STAFF_BASE}?page=${page_no}&limit=20`;
    if (search) {
        api_url = `${api_url}&key=${search}`;
    }
    dispatch(update_staff_loader({ loader: loader }));
    Api('get', api_url, '', history).then(res => {
        const { response, statusCode } = res;
        if (statusCode === 200) {
            let final_data = page_no > 1 ? prev_data.concat(response) : response
            let pagination_status;
            if (response.length < 20) {
                pagination_status = false;
            } else if (response.length === 20) {
                pagination_status = true;
            }
            dispatch(update_staffs({
                arr_response: final_data,
                page_no,
                pagination: pagination_status,
                search
            }))
        } else {
            dispatch(update_staff_loader({ loader: false }));
        }

    })
}


export const create_staff = (history, dispatch, post_data, success, failed) => {
    Api('post', STAFF_BASE, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Staff Created Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(add_single_staff({
                new_data: res.response
            }));
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })
        }

    })
}


export const update_staff = (history, dispatch, post_data, staff_id, success, failed) => {
    let api_url = STAFF_BASE + staff_id + '/';
    Api('put', api_url, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Agent Details Updated',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(update_single_staff({
                staff_id: staff_id,
                new_data: res.response
            }))
        }else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })
        }
    })
}


export const delete_staff = (history, dispatch, staff_id) => {
    let api_url = STAFF_BASE + staff_id + '/';
    Api('delete', api_url, '', history).then(res => {
        if (res.statusCode === 200) {
            Swal.fire(
                'Deleted!',
                'Staff deleted!',
                'success'
            )
            dispatch(remove_single_staff({
                staff_id: staff_id,
            }))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.error_app,
            })
        }
    })
}

export const status_change_staff = (history, dispatch, post_data, staff_id, success, failed) => {

    let api_url = STAFF_BASE + staff_id + '/';
    Api('put', api_url, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire(
                'Updated!',
                'Staff status changed!',
                'success'
            );
            dispatch(update_single_staff_status({
                staff_id: staff_id,
                new_data: res.response,
            }))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.error_app,
            })
        }
    })
}

