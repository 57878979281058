import { createSlice } from '@reduxjs/toolkit';

export const collegeSlice = createSlice({
    name: 'college',
    initialState: {

        arr_colleges: [],
        college_page: 1,
        college_search: '',
        college_pagination: true,
        college_loader: false,
        college_acd_yr: null,
        college_acd_yr_list: [],

        arr_students: [],
        college_total_students: 0,
        college_direct_students: 0,
        college_agency_students: 0,
        college_students_page: 1,
        college_students_search: '',
        college_students_pagination: true,
        college_students_loader: false,

        arr_college_course: [],
        college_course_page: 1,
        college_course_search: '',
        college_course_pagination: true,
        college_course_loader: false,

        arr_addon_course: [],

        arr_college_batch: [],
        college_batch_page: 1,
        college_batch_search: '',
        college_batch_pagination: true,
        college_batch_loader: false,
        college_batch_course_filter: null,

    },
    reducers: {

        // =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>
        //                        C O L L E G E
        // =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>
        update_colleges: (state, action) => {
            const { arr_response, page_no, pagination, search, academic_yr } = action.payload;
            state.arr_colleges = arr_response;
            state.college_page = page_no;
            state.college_pagination = pagination;
            state.college_search = search;
            state.college_acd_yr = academic_yr
            state.college_loader = false;
        },
        update_college_loader: (state, action) => {
            const { loader } = action.payload;
            state.college_loader = loader;
        },
        add_single_college: (state, action) => {
            const { new_data } = action.payload;
            state.arr_colleges.unshift(new_data);
        },
        remove_single_college: (state, action) => {
            const { college_id } = action.payload;
            let latest_list = state.arr_colleges.filter(item => item.id !== college_id);
            state.arr_colleges = latest_list;
        },
        update_single_college: (state, action) => {
            const { college_id, new_data } = action.payload;
            let array_item = state.arr_colleges.find(item => item.id === college_id);
            let arra_item_index = state.arr_colleges.indexOf(array_item);
            state.arr_colleges[arra_item_index] = new_data;
        },

        update_academic_yr: (state, action) => {
            state.college_acd_yr_list = action.payload.data;
        },
        update_single_academic_yr: (state, action) => {
            const { id, data } = action.payload;
            let array_item = state.college_acd_yr_list.find(item => item.id === id);
            let arra_item_index = state.college_acd_yr_list.indexOf(array_item);
            state.college_acd_yr_list[arra_item_index] = data;
        },
        remove_academic_yr: (state, action) => {
            const { acd_id } = action.payload;
            let latest_list = state.college_acd_yr_list.filter(item => item.id !== acd_id);
            state.college_acd_yr_list = latest_list;
        },

        // =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>
        //            C O L L E G E    S T U D N E T S
        // =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>

        update_college_students: (state, action) => {
            const { arr_response, total_students, direct_students, agency_students, page_no, pagination, search } = action.payload;
            state.arr_students = arr_response;
            state.college_students_page = page_no;
            state.college_students_pagination = pagination;
            state.college_students_search = search;
            state.college_students_loader = false;
            state.college_total_students = total_students;
            state.college_direct_students = direct_students;
            state.college_agency_students = agency_students;
        },
        update_college_students_loader: (state, action) => {
            const { loader } = action.payload;
            state.college_students_loader = loader;
        },

        // =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>
        //             C O L L E G E    C O U R S E
        // =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>
        update_college_courses: (state, action) => {
            const { arr_response, page_no, pagination, search } = action.payload;
            state.arr_college_course = arr_response;
            state.college_course_page = page_no;
            state.college_course_pagination = pagination;
            state.college_course_search = search;
            state.college_course_loader = false;
        },
        update_college_course_loader: (state, action) => {
            const { loader } = action.payload;
            state.college_course_loader = loader;
        },
        add_single_college_course: (state, action) => {
            const { new_data } = action.payload;
            state.arr_college_course.unshift(new_data);
        },
        remove_single_college_course: (state, action) => {
            const { course_id } = action.payload;
            let latest_list = state.arr_college_course.filter(item => item.id !== course_id);
            state.arr_college_course = latest_list;
        },
        update_single_college_course: (state, action) => {
            const { course_id, new_data } = action.payload;
            let array_item = state.arr_college_course.find(item => item.id === course_id);
            let arra_item_index = state.arr_college_course.indexOf(array_item);
            state.arr_college_course[arra_item_index] = new_data;
        },


        // =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>
        //                 A D D O N   C O U R S E S
        // =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>
        update_addon_course: (state, action) => {
            const { arr_response } = action.payload;
            state.arr_addon_course = arr_response;
        },
        add_single_addon_course: (state, action) => {
            const { new_data } = action.payload;
            state.arr_addon_course.unshift(new_data);
        },
        remove_single_addon_course: (state, action) => {
            const { course_id } = action.payload;
            let latest_list = state.arr_addon_course.filter(item => item.id !== course_id);
            state.arr_addon_course = latest_list;
        },
        update_single_addon_course: (state, action) => {
            const { course_id, new_data } = action.payload;
            let array_item = state.arr_addon_course.find(item => item.id === course_id);
            let arra_item_index = state.arr_addon_course.indexOf(array_item);
            state.arr_addon_course[arra_item_index] = new_data;
        },


        // =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>
        //             C O L L E G E    B A T C H
        // =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>
        update_college_batchs: (state, action) => {
            const { arr_response, page_no, pagination, search, course_filter } = action.payload;
            state.arr_college_batch = arr_response;
            state.college_batch_page = page_no;
            state.college_batch_pagination = pagination;
            state.college_batch_search = search;
            state.college_batch_loader = false;
            state.college_batch_course_filter = course_filter;
        },
        update_college_batch_loader: (state, action) => {
            const { loader } = action.payload;
            state.college_batch_loader = loader;
        },
        add_single_college_batch: (state, action) => {
            const { new_data } = action.payload;
            state.arr_college_batch.unshift(new_data);
        },
        remove_single_college_batch: (state, action) => {
            const { batch_id } = action.payload;
            let latest_list = state.arr_college_batch.filter(item => item.id !== batch_id);
            state.arr_college_batch = latest_list;
        },
        update_single_college_batch: (state, action) => {
            const { batch_id, new_data } = action.payload;
            let array_item = state.arr_college_batch.find(item => item.id === batch_id);
            let arra_item_index = state.arr_college_batch.indexOf(array_item);
            state.arr_college_batch[arra_item_index] = new_data;
        },
    }
})

export const {
    update_colleges,
    add_single_college,
    remove_single_college,
    update_single_college,
    update_college_loader,

    update_academic_yr,
    update_single_academic_yr,
    remove_academic_yr,

    update_college_students,
    update_college_students_loader,

    update_college_courses,
    add_single_college_course,
    remove_single_college_course,
    update_single_college_course,
    update_college_course_loader,

    update_addon_course,
    add_single_addon_course,
    remove_single_addon_course,
    update_single_addon_course,

    update_college_batchs,
    add_single_college_batch,
    remove_single_college_batch,
    update_single_college_batch,
    update_college_batch_loader,

} = collegeSlice.actions

export const selectCollegeDatas = state => state.college;

export default collegeSlice.reducer;