import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabStudentsList from './tab_stud_list'
import TabCourseList from './tab_course_list'
import TabBatchList from './tab_batch_list'

import { fetch_college_batches, fetch_college_courses, fetch_college_students } from '../store/action'
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import { selectCollegeDatas } from '../store/slice';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: 'var(--cl_violet)',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: 'var(--cl_violet)',
      opacity: 1,
    },
    '&$selected': {
      color: 'var(--cl_violet)',
      fontWeight: 'bold',
    },
    '&:focus': {
      color: 'var(--cl_violet)',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10
  },
  demo2: {
    backgroundColor: '#2e1534',
  },
}));

export default function CustomizedTabs() {
  const classes = useStyles();

  const route_history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { college_acd_yr, college_batch_course_filter } = useSelector(selectCollegeDatas);

  let routing_path = '/college/detail/';
  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      routing_path = routing_path + 'students';
    } else if (newValue === 1) {
      routing_path = routing_path + 'courses';
    } else if (newValue === 2) {
      routing_path = routing_path + 'batch';
    }
    route_history.push(routing_path, state);
  };


  let default_page = 0;
  if (route_history.location.pathname === routing_path + 'students') {
    default_page = 0;
  } else if (route_history.location.pathname === routing_path + 'courses') {
    default_page = 1;
  } else if (route_history.location.pathname === routing_path + 'batch') {
    default_page = 2;
  }


  useEffect(() => {
    const defaultApiCall = () => {
      if (state) {
        fetch_college_students(route_history, dispatch, state.college_id, 1, [], '',true, college_acd_yr)
        fetch_college_courses(route_history, dispatch, state.college_id, 1, [], '',true, college_acd_yr)
        fetch_college_batches(route_history, dispatch, state.college_id, 1, [], '',true, college_batch_course_filter, college_acd_yr)
      } else {
        route_history.push('/college');
      }
    }
    defaultApiCall();
  }, [route_history, dispatch, state, college_acd_yr, college_batch_course_filter])

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <AntTabs value={default_page} onChange={handleChange} aria-label="ant example">
          <AntTab label="Students" />
          <AntTab label="Course" />
          <AntTab label="Batch" />
        </AntTabs>
        <Switch>
          <Route path="/college/detail/students" component={TabStudentsList} />
          <Route path="/college/detail/courses" component={TabCourseList} />
          <Route path="/college/detail/batch" component={TabBatchList} />
          <Redirect
            from="/college/detail/"
            to={{
              pathname: "/college/detail/students",
              state: state
            }}
          />
        </Switch>
      </div>

    </div>
  );
}
