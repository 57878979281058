import React, { useState } from 'react'
import Scrollview from 'react-custom-scrollbars'
import { Visibility, Edit, Delete } from '@material-ui/icons'
import { useRouteMatch, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useSelector, useDispatch } from 'react-redux'
import { selectAgencyDatas } from '../store/slice'
import { update_selected_agent } from '../store/slice'
import AgentModal from './agent_modal'
import { fetch_agencies, delete_agency } from '../store/action'
import { CircularProgress } from '@material-ui/core'
import { selectPermissionDatas } from '../../../common-components/store/slice'


function ListAgency({ modalAction }) {

    const { path } = useRouteMatch();
    const store_agency_data = useSelector(selectAgencyDatas);
    const { agency_detail } = useSelector(selectPermissionDatas);
    const route_history = useHistory();
    const dispatch = useDispatch();



    const [Modal, setModal] = useState(false);
    const [SelectedAgency, setSelectedAgency] = useState({});

    const [AgentName, setAgentName] = useState('');
    const [AgencyName, setAgencyName] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [SecPhoneNumber, setSecPhoneNumber] = useState('');
    const [Email, setEmail] = useState('');
    const [DueAmount, setDueAmount] = useState('');
    const [EmailError, setEmailError] = useState('');

    const { 
        arr_agencies, 
        agency_loader, 
        agency_list_loader, 
        agency_page, 
        agency_pagination, 
        agency_filter, 
        agency_financial_yr,
        agency_search } = store_agency_data

    const delete_fx = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                delete_agency(route_history, dispatch, id);
            }
        })
    }

    const ScrollPos = (val) => {
        const { scrollHeight, scrollTop, clientHeight } = val.target;
        let bottom = scrollHeight - scrollTop === clientHeight
        if (bottom) {
            if (agency_pagination) {
                let page_no_val = agency_page + 1;
                fetch_agencies(route_history, dispatch, page_no_val, arr_agencies, agency_filter, agency_search, false, true, agency_financial_yr)
            }

        }
    }

    return (
        <div className="app-main-list-layout">
            <div className="app-table-layout">

                <table className="app_table_main">
                    <tbody className="app_table_tbody">
                        <tr style={{ fontWeight: 'bold', fontSize: 12 }}>
                            <td style={{ width: 80, padding: 0 }}>Agent ID</td>
                            <td style={{ width: 150, padding: 0 }}>Agent Name</td>
                            <td style={{ width: 150, padding: 0 }}>Agency Name</td>
                            <td style={{ width: 120, padding: 0 }}>No Admissions</td>
                            <td style={{ width: 120, padding: 0 }}>Contact No</td>
                            {
                                agency_detail && (
                                    <>
                                        <td style={{ width: 120, padding: 0 }}>Previous Due Amount</td>
                                        <td style={{ width: 120, padding: 0 }}>Paid Amount</td>
                                        <td style={{ width: 120, padding: 0 }}>Pending Amount</td>
                                        <td style={{ width: 120, padding: 0 }}>Total Amount</td>
                                    </>
                                )
                            }
                            <td style={{ padding: 0 }}>Manage</td>

                        </tr>
                    </tbody>
                </table>

                <Scrollview
                    onScroll={e => ScrollPos(e)}
                    style={{ height: '70vh', position: 'relative' }}
                >
                    {
                        !agency_loader ? (

                            <>
                                {arr_agencies.length > 0 ? (
                                    <>
                                    <table className="app_table_main">
                                        <tbody className="app_table_tbody">
                                            {
                                                arr_agencies.map((item, key) => {
                                                    const { id, consultant_name, consultancy_name, no_of_admissions, primary_phone, secondary_phone, email, paid_amount, pending_amount, total_amount, previous_due_amount } = item
                                                    return (
                                                        <tr key={key} className="app-table-row">
                                                            <td style={{ width: 80, padding: 0 }}>AG{id}</td>
                                                            <td style={{ width: 150, padding: 0 }}>{consultant_name} </td>
                                                            <td style={{ width: 150, padding: 0 }}>{consultancy_name}</td>
                                                            <td style={{ width: 120, padding: 0 }}>{no_of_admissions}</td>
                                                            <td style={{ width: 120, padding: 0 }}>{primary_phone}</td>
                                                            {
                                                                agency_detail && (
                                                                    <>
                                                                        <td style={{ width: 120, padding: 0 }}>{previous_due_amount}</td>
                                                                        <td style={{ width: 120, padding: 0 }}>{paid_amount}</td>
                                                                        <td style={{ width: 120, padding: 0 }}>{pending_amount}</td>
                                                                        <td style={{ width: 120, padding: 0 }}>{total_amount}</td>
                                                                    </>
                                                                )
                                                            }

                                                            <td style={{ padding: 0 }}>
                                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    {agency_detail ? <div onClick={() => { dispatch(update_selected_agent({ arr_response: item })); route_history.push(path + '/detail', item,) }} style={{ color: '#000', textDecoration: 'none', cursor: 'pointer' }}>
                                                                        <Visibility style={{ fontSize: 16, marginRight: 20 }} />
                                                                    </div> : null}

                                                                    <Edit
                                                                        style={{ fontSize: 16, marginRight: 20, cursor: 'pointer' }}
                                                                        onClick={() => {

                                                                            setSelectedAgency(item);
                                                                            setAgentName(consultant_name);
                                                                            setAgencyName(consultancy_name);
                                                                            setPhoneNumber(primary_phone);
                                                                            setSecPhoneNumber(secondary_phone);
                                                                            setEmail(email);
                                                                            setDueAmount(previous_due_amount)
                                                                            modalAction(false);
                                                                            setModal(true);
                                                                        }}
                                                                    />
                                                                    <Delete onClick={() => delete_fx(id)} style={{ fontSize: 16, marginRight: 20, cursor: 'pointer' }} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    {
                                        agency_list_loader && (
                                            <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
                                                <CircularProgress size={30} />
                                            </div>
                                        )
                                    }
                                    </>
                                ) : (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                                        <p style={{ fontSize: 14, fontWeight: 'bold' }}>No Data Found</p>
                                    </div>
                                )
                                }
                            </>

                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                                <CircularProgress size={26} />
                            </div>
                        )
                    }
                </Scrollview>
            </div>
            <AgentModal
                modalStatus={Modal}
                modalAction={setModal}
                data={SelectedAgency}
                AgentName={AgentName}
                setAgentName={setAgentName}
                AgencyName={AgencyName}
                setAgencyName={setAgencyName}
                PhoneNumber={PhoneNumber}
                setPhoneNumber={setPhoneNumber}
                SecPhoneNumber={SecPhoneNumber}
                setSecPhoneNumber={setSecPhoneNumber}
                Email={Email}
                setEmail={setEmail}
                EmailError={EmailError}
                setEmailError={setEmailError}
                DueAmount={DueAmount}
                setDueAmount={setDueAmount}
            />
        </div>
    )
}

export default ListAgency
