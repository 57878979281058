import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

function AddonModal({
    modalStatus,
    modalAction,
    AddonCourseName,
    setAddonCourseName,
    buttonClick,
    buttonLoader,

}) {

    const classes = useStyles();


    const handleClose = () => {
        modalAction(false)
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >

            <div style={{ width: '25vw', height: '15vh' }} className="app-modal-main">
                <p style={{ fontSize: 16, fontWeight: 'bold' }}>Create Ad-On Course</p>
                <input
                    type="text"
                    placeholder="Course Name"
                    style={{ border: 0, borderBottom: '1px solid grey', width: '100%', marginTop: 30 }}
                    value={AddonCourseName}
                    onChange={txt => setAddonCourseName(txt.target.value)}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '10px 0px', marginTop: 20 }}>
                    <button onClick={() => handleClose()} style={{ height: 30, backgroundColor: 'grey' }} className="app-common-btn">Cancel</button>
                    <button onClick={() => buttonClick()} style={{ height: 30 }} className={buttonLoader ? "app-common-btn disabled-btn" : "app-common-btn"}>

                        {buttonLoader ? (<CircularProgress size={12} color="inherit" />) : 'Create'}

                    </button>

                </div>
            </div>
        </Modal>
    );
}



export default AddonModal
