import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AddCircleOutline, ArrowBack, Edit, PersonAdd } from '@material-ui/icons';
import TransactionModal from '../components/transaction_modal';
import { useDispatch, useSelector } from 'react-redux';
import Api from '../../../store/api';
import { BASE_MAIN, COLLEGE_BASE, AGENCY_BASE, FINANCIAL_YEAR_CRUD } from '../../../store/endpoint';
import { create_student, update_student, create_addon_course, create_fee_recipt, fetch_student_due_fees, print_individual_fee_recipt, update_student_active_status } from '../store/action';
import AddonModalRender from '../components/addon_course_modal';
import { selectStudentsDatas } from '../store/slice';
import { CircularProgress } from '@material-ui/core'
import Swal from 'sweetalert2';
import { selectCollegeDatas } from '../../college/store/slice';
import { fetch_all_academic_yr } from '../../college/store/action';
import { selectAgencyDatas } from '../../agency/store/slice';
import dayjs from 'dayjs';


function Detail() {

    const route_history = useHistory();
    const dispatch = useDispatch();
    const { state } = useLocation();
    console.log('STATE',state)
    let name_dat = '';
    let address_dat = '';
    let pincode_dat = '';
    let student_contact_dat = '';
    let parent_contact_dat = '';
    let whatsapp_contact_dat = '';
    let email_dat = '';
    let college_dat = '';
    let batch_dat = '';
    let agency_dat = '';
    let financial_yr_dat = '';
    let remarks_dat = '';
    let addon_course_dat = '';
    let fees_list_dat = [];
    let seat_type = '';
    let seat_converted = false;
    let student_status = false;
    let acd_yr = null

    // console.log('*******************************************************')
    // console.log('**********************SAHAD TESTING *******************')
    // console.log('*******************************************************')

    // console.log(state)

    if (state) {
        const {
            name,
            address,
            pincode,
            student_contact,
            parent_contact,
            whatsapp_contact,
            email,
            batch,
            agency,
            remarks,
            addon_course,
            college_id,
            fees_str,
            students_type, 
            convertion_seat,
            active_students,
            financial_year,
            academic_year } = state;
        name_dat = name;
        address_dat = address;
        pincode_dat = pincode;
        student_contact_dat = student_contact;
        parent_contact_dat = parent_contact;
        whatsapp_contact_dat = whatsapp_contact;
        email_dat = email;
        batch_dat = batch;
        agency_dat = agency;
        remarks_dat = remarks;
        addon_course_dat = addon_course;
        college_dat = college_id;
        fees_list_dat = fees_str;
        seat_type = students_type;
        seat_converted = convertion_seat;
        student_status = active_students;
        financial_yr_dat = financial_year;
        acd_yr = academic_year;
    }


    const [Modal, setModal] = useState(false);
    const [AddonModal, setAddonModal] = useState(false);
    const [AddonCourseName, setAddonCourseName] = useState('');
    const [Course, setCourse] = useState('');

    const [StudentName, setStudentName] = useState(name_dat);
    const [StudentAddress, setStudentAddress] = useState(address_dat);
    const [StudentPincode, setStudentPincode] = useState(pincode_dat);
    const [StudentPhone, setStudentPhone] = useState(student_contact_dat);
    const [StudentParentPhone, setStudentParentPhone] = useState(parent_contact_dat);
    const [StudentWhatsapp, setStudentWhatsapp] = useState(whatsapp_contact_dat);
    const [StudentEmail, setStudentEmail] = useState(email_dat);
    const [StudentActiveStatus, setStudentActiveStatus] = useState(student_status);

    const [College, setCollege] = useState(college_dat);
    const [Batch, setBatch] = useState(batch_dat);
    const [AddOnCourse, setAddOnCourse] = useState(addon_course_dat);
    const [Agency, setAgency] = useState(agency_dat);
    const [FinancialYr, setFinancialYr] = useState(financial_yr_dat)
    const [Remarks, setRemarks] = useState(remarks_dat);
    const [StudentFees, setStudentFees] = useState(fees_list_dat);
    const [SeatConveted, setSeatConveted] = useState(seat_converted);

    const [CollegeData, setCollegeData] = useState([]);
    const [BatchData, setBatchData] = useState([]);
    const [AddonCourseData, setAddonCourseData] = useState([]);
    const [SeatType, setSeatType] = useState(seat_type);
    const [AgencyData, setAgencyData] = useState([]);
    const [FinancialYrData, setFinancialYrData] = useState([])
    const [EmailError, setEmailError] = useState('');
    const [StudentPhoneError, setStudentPhoneError] = useState('');
    const [ParentPhoneError, setParentPhoneError] = useState('');
    const [WhatsaapError, setWhatsaapError] = useState('');
    const [PinError, setPinError] = useState('');

   
    const [FeeAmount, setFeeAmount] = useState('');
    const [FeeDescription, setFeeDescription] = useState('');
    const [FeeCollectionPlace, setFeeCollectionPlace] = useState('');
    const [FeeModePay, setFeeModePay] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);
    const [PrintButtonLoader, setPrintButtonLoader] = useState(false);

    const [AcdYr, setAcdYr] = useState(acd_yr)
    const [isAdmissionFee, setIsAdmissionFee] = useState(false)


    const { arr_students_fees, arr_student_fee_general_info, student_due_loader } = useSelector(selectStudentsDatas);
    const { college_acd_yr_list } = useSelector(selectCollegeDatas)
    const { selected_agent } = useSelector(selectAgencyDatas)

    let add_btn_status = false;
    if (StudentName && College && Batch) {
        add_btn_status = true;
    }

    let update_btn_status = false;
    if (StudentName !== name_dat || SeatType !== seat_type || StudentAddress !== address_dat || StudentPincode !== pincode_dat
         || StudentPhone !== student_contact_dat || StudentParentPhone !== parent_contact_dat || StudentWhatsapp !== whatsapp_contact_dat
          || StudentEmail !== email_dat || College !== college_dat || Batch !== batch_dat || AddOnCourse !== addon_course_dat
           || Agency !== agency_dat || Remarks !== remarks_dat || StudentFees !== fees_list_dat || SeatConveted !== seat_converted
           || FinancialYr !== financial_yr_dat ) {
        update_btn_status = true
    }

    let general_button = false;
    state ? general_button = update_btn_status : general_button = add_btn_status


    let fees_add_btn_status = false;
    if (FeeAmount && FeeDescription && FeeCollectionPlace && FeeModePay) {
        fees_add_btn_status = true;
    }

    const fetch_batches = (college_id) => {
        //let api_url = `${BASE_MAIN}college/${college_id}/course/batch/crud/?page=1&limit=60`;
        let api_url = `${BASE_MAIN}college/course/batch/available/?college_id=${college_id}`
        const success = (res) => {
            const { response } = res;
            console.log(res)
            setBatchData(response);
        }
        Api('get', api_url, '', route_history, success);
    }



    const fetch_addon_courses = (course_id) => {
        let api_url = `${BASE_MAIN}college/course/${course_id}/addon/crud/?page=${1}&limit=60`;
        const success = (res) => {
            const { response } = res;
            setAddonCourseData(response);
        }
        Api('get', api_url, '', route_history, success);
    }


    const setFeeStucture = (duration, duration_type) => {

        let i = 0;
        let fee_sturc = []

        for (i = 0; i < duration; i++) {
            let name_sturc = duration_type + ' ' + (i + 1);
            fee_sturc.push({
                amount: '',
                name: name_sturc
            });
        }


        fee_sturc.push({
            amount: '',
            name: 'Addon Course'
        });


        fee_sturc.push({
            amount: '',
            name: 'Admission Fee'
        });

        fee_sturc.push({
            amount: 0,
            name: 'Total'
        });

        setStudentFees(fee_sturc);
    }

    useEffect(() => {
    
        let api_url_clg = `${COLLEGE_BASE}?page=1&limit=250`;
        let api_url_agncy = `${AGENCY_BASE}?page=1&limit=250`;
        let api_url_financial = FINANCIAL_YEAR_CRUD;
        fetch_all_academic_yr(route_history, dispatch)
        const success_clg = (res) => {
            const { response } = res;
            setCollegeData(response);
        }
        const success_agency = (res) => {
            const { response } = res;
            setAgencyData(response);
        }
        const success_financial = (res) => {
            const { response } = res;
            setFinancialYrData(response);
        }
        Api('get', api_url_clg, '', route_history, success_clg);
        Api('get', api_url_agncy, '', route_history, success_agency);
        Api('get', api_url_financial, '', route_history, success_financial);
        

    }, [route_history, dispatch])



    useEffect(() => {
        const apicall_states = () => {
            if (state) {
                // Initial Batch Fetching
                let api_url_a = `${BASE_MAIN}college/${state.college_id}/course/batch/crud/?page=1&limit=60`;
                const success_a = (res) => {
                    const { response } = res;
                    setBatchData(response);
                }
                Api('get', api_url_a, '', route_history, success_a);

                // Initial AddonCourse Fetching
                let api_url_b = `${BASE_MAIN}college/course/${state.course_id}/addon/crud/?page=${1}&limit=60`;
                const success_b = (res) => {
                    const { response } = res;
                    setAddonCourseData(response);
                }
                Api('get', api_url_b, '', route_history, success_b);
            }
        }
        apicall_states();
    }, [dispatch, route_history, state])


    useEffect(() => {
        if (state) {
            fetch_student_due_fees(route_history, dispatch, state.id)
        }
    }, [route_history, dispatch, state])



    const save_button_click = () => {
        // setButtonLoader(true);
        setButtonLoader(true)

        const success = (res) => {
            // setButtonLoader(false);
            setButtonLoader(false)
            setStudentPhoneError('');
            setEmailError('');
            setParentPhoneError('');
            setWhatsaapError('');

            const { response } = res;
            let obj = {...response, reDir : state ? state.reDir : null}
            console.log(res)
            route_history.push('/students/detail', obj)
        }
        const failed = () => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
            setButtonLoader(false)

        }
        if (StudentEmail) {
            let fess_struc = [];
            for (let i = 0; i < StudentFees.length - 1; i++) {
                let amount = StudentFees[i].amount;
                fess_struc.push(amount);
            }

            let postData = {
                name: StudentName,
                address: StudentAddress,
                pincode: StudentPincode,
                student_contact: StudentPhone,
                parent_contact: StudentParentPhone,
                whatsapp_contact: StudentWhatsapp,
                email: StudentEmail,
                batch: Batch,
                remarks: Remarks,
                agency: Agency || '',
                addon_course: AddOnCourse,
                fees_list: fess_struc,
                students_type: SeatType,
                financial_year: FinancialYr,
                academic_year: AcdYr
            }

            if(state && state.agency) {
                if (Agency !== state.agency) postData.agency = Agency;
            } 
            

            if(state && seat_converted !== SeatConveted) {
                postData.convertion_seat = SeatConveted
            }

            const email_regex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
            const phoneno = new RegExp(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/);
            const pinno = new RegExp(/^(\d{4}|\d{6})$/);

            let email_status = email_regex.test(StudentEmail);
            let phone_status = phoneno.test(StudentPhone);
            let parent_phone_status = phoneno.test(StudentParentPhone);
            let whatsapp_status = phoneno.test(StudentWhatsapp);
            let pin = pinno.test(StudentPincode);

            // console.log('phone status',phone_status);
            // const [StudentPhoneError, setStudentPhoneError] = useState('');

            if (email_status && phone_status && parent_phone_status && whatsapp_status && pin) {
                setEmailError('')
                setStudentPhoneError('')


                if (state) {
                    //console.log('redy for update api call', postData)
                    update_student(route_history, dispatch, postData, state.id, success, failed)
                } else {
                    //console.log('redy for Create api call', postData)
                    create_student(route_history, dispatch, postData, success, failed)
                }

            } else {

                email_status ? setEmailError('') : setEmailError('Invalid Email Id')
                phone_status ? setStudentPhoneError('') : setStudentPhoneError('Invalid number')
                parent_phone_status ? setParentPhoneError('') : setParentPhoneError('Invalid number')
                whatsapp_status ? setWhatsaapError('') : setWhatsaapError('Invalid number')
                pin ? setPinError('') : setPinError('Invalid number')

                setButtonLoader(false)


            }

        } else {
            let fess_struc = [];
            for (let i = 0; i < StudentFees.length - 1; i++) {
                let amount = StudentFees[i].amount;
                fess_struc.push(amount);
            }

            let postData = {
                name: StudentName,
                address: StudentAddress,
                pincode: StudentPincode,
                student_contact: StudentPhone,
                parent_contact: StudentParentPhone,
                whatsapp_contact: StudentWhatsapp,
                batch: Batch,
                agency: Agency || '',
                remarks: Remarks,
                addon_course: AddOnCourse,
                fees_list: fess_struc,
                students_type: SeatType,
                financial_year: FinancialYr,
                academic_year: AcdYr
            }

            // if(Agency) {

            // }
            // if(state && state.agency) {
            //     if (Agency !== state.agency) postData.agency = Agency;
            // } 
            

            if(state && seat_converted !== SeatConveted) {
                postData.convertion_seat = SeatConveted
            }

            if (state) {
                // console.log('redy for update api call', postData)
                update_student(route_history, dispatch, postData, state.id, success, failed)
            } else {
                //console.log('redy for Create api call', postData)
                create_student(route_history, dispatch, postData, success, failed)
            }
        }

    }


    const create_addon_course_fx = () => {
        setButtonLoader(true);
        let postData = {
            course: Course,
            name: AddonCourseName
        }
        const success = (res) => {
            const { response, statusCode } = res
            if (statusCode === 200) {
                setButtonLoader(false);
                setAddonModal(false)
                setAddonCourseName('');
                setAddonCourseData(item => [...item, response])
            }
        }
        const failed = () => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        }
        create_addon_course(route_history, postData, success, failed)
    }


    const create_fee_recipt_fx = () => {
        setButtonLoader(true);

        let postData = {
            amount: FeeAmount,
            student: state.id,
            description: FeeDescription,
            collection_place: FeeCollectionPlace,
            mode_of_payment: FeeModePay,
        }
        if(isAdmissionFee)
                postData = {...postData, admission_fees : 1}
        const success = (res) => {
            const { statusCode } = res
            if (statusCode === 200) {
                setButtonLoader(false);
                setFeeAmount('');
                setFeeDescription('');
                setFeeCollectionPlace('');
                setFeeModePay('');
                setIsAdmissionFee(false)
                if (state) {
                    fetch_student_due_fees(route_history, dispatch, state.id)
                }
            }
        }
        const failed = () => {
            setButtonLoader(false);
            setIsAdmissionFee(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        }
        create_fee_recipt(route_history, dispatch, postData, success, failed);
    }

    const individual_recipt_print_fx = (id) => {
        setPrintButtonLoader(true);
        const success = () => setPrintButtonLoader(false);
        const failed = () => {
            setPrintButtonLoader(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        }
        print_individual_fee_recipt(route_history, id, success, failed)
    }

    const revokeStudentBtn = () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'swal-btn btn-danger',
              cancelButton: 'swal-btn btn-cancel'
            },
            buttonsStyling: false
          })
          swalWithBootstrapButtons.fire({
            title: 'Are you sure!',
            html:
            '<h3 style="text-align: left; margin-bottom: 0;">Discontinued Date:</h3>' +
            '<input style="height: 45px; margin-bottom: 50px;" type="date" placeholder="Discontinue Date" id="swal-input1" class="swal2-input">' +
            '<p style="text-align: left; margin-left: 10px;">If you want to change discontinued date after revoking a student</p>' + 
            '<p style="text-align: left; margin-left: 10px; font-size: 16px;">1. change revoked status to active</p>' + 
            '<p style="text-align: left; margin-left: 10px; font-size: 16px;">2. Then revoke again!</p>',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Revoke',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                let input = document.getElementById('swal-input1');
                console.log(input.value)

                let date_val = 'nodate';
                input.value ? date_val = input.value : date_val = 'nodate'

                const success = () => {
                    setStudentActiveStatus(false);
                }
                const failed = () => {

                    setStudentActiveStatus(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    })
                }

                update_student_active_status(route_history, state.id, 'inactive', date_val, success, failed);
          
            },
          })
    }

    const activeStudentBtn = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to change student status?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Active!'
          }).then((result) => {
            if (result.isConfirmed) {

                const success = () => {
                    setStudentActiveStatus(true);
                }
                const failed = () => {

                    setStudentActiveStatus(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    })
                }

                update_student_active_status(route_history, state.id, 'active', 'nodate', success, failed);
           
            }
          })
    }

    return (
        <div className="app-dynamic-layout">
            <div className="student-detail-layout">

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>
                        <Link to={{ pathname: state && state.reDir ? "/agency/detail" : "/students", state : state&&state.reDir ? selected_agent : null}} className="app-nav-back-btn">
                            <ArrowBack />
                        </Link>
                        <h4 style={{ margin: 0 }}>Student Informations</h4>
                    </div>
                </div>


                <div className="student-dtl-section-layout">
                    <div className="section-1">
                        <div style={{ marginBottom: 10 }} className={state ? 'createbutton' : 'createbutton-none'}>

                            {
                                state && (
                                    <>
                                    {
                                        StudentActiveStatus ? (
                                            <button 
                                                onClick={() => revokeStudentBtn()}
                                                style={{ fontSize: 16, backgroundColor: 'red' }}
                                                className="app-common-btn">Revoke
                                            </button>
                                        ) : (
                                            <button 
                                                onClick={() => activeStudentBtn()}
                                                style={{ fontSize: 16, backgroundColor: 'var(--cl_violet)' }}
                                                className="app-common-btn">Active
                                            </button>
                                        )
                                    }
                                    </>
                                )
                            }
                            
                            {state ? (
                                general_button ? (
                                    <button
                                        onClick={() => save_button_click()}
                                        style={{ fontSize: 16 }}
                                        className={buttonLoader ? "app-common-btn disabled-btn" : "app-common-btn"}>
                                        {buttonLoader ? (<CircularProgress size={20} color="inherit" />) : 'Update'}

                                    </button>
                                ) : (<button className="app-common-btn disabled-btn"> <Edit style={{ fontSize: 24, marginRight: 10 }} /> Update</button>)
                            ) : (
                                general_button ? (
                                    <button
                                        onClick={() => save_button_click()}
                                        style={{ fontSize: 16 }}
                                        className={buttonLoader ? "app-common-btn disabled-btn" : "app-common-btn"}>
                                        {buttonLoader ? (<CircularProgress size={20} color="inherit" />) : 'Create'}

                                    </button>
                                ) : (<button className="app-common-btn disabled-btn"> <PersonAdd style={{ fontSize: 24, marginRight: 10 }} /> Create </button>)

                            )}

                            
                        </div>

                        <div className="student-main-grid-layout">

                            <div className="stud-grid-item1">
                                <p style={{ fontSize: 16, fontWeight: 'bold' }}>Basic Details</p>
                                <div className="student-dtl-textinput">
                                    <p style={{ flex: 2, fontWeight: '600' }}>Name</p>
                                    <p style={{ flex: 0.5, fontWeight: '600' }}>:</p>
                                    <input
                                        style={{ flex: 4 }}
                                        type="text"
                                        placeholder="Name"
                                        value={StudentName || ''}
                                        onChange={txt => setStudentName(txt.target.value)}
                                    />
                                </div>

                                <div className="student-dtl-textinput">
                                    <p style={{ flex: 2, fontWeight: '600' }}>Address</p>
                                    <p style={{ flex: 0.5, fontWeight: '600' }}>:</p>
                                    <input
                                        style={{ flex: 4 }}
                                        type="text"
                                        placeholder="Address"
                                        value={StudentAddress || ''}
                                        onChange={txt => setStudentAddress(txt.target.value)}
                                    />
                                </div>

                                <div className="student-dtl-textinput">
                                    <p style={{ flex: 2, fontWeight: '600', color: PinError ? 'red' : 'black' }}>PinCode</p>

                                    <p style={{ flex: 0.5, fontWeight: '600' }}>:</p>
                                    <input
                                        style={{ flex: 4 }}
                                        type="text"
                                        placeholder={PinError ? PinError : 'PinCode'}
                                        value={StudentPincode || ''}
                                        onChange={txt => {
                                            const value = txt.target.value.replace(/\D/g, "");
                                            setStudentPincode(value)
                                        }}
                                    />
                                </div>

                                <div className="student-dtl-textinput">
                                    <p style={{ flex: 2, fontWeight: '600', color: StudentPhoneError ? 'red' : 'black' }}>Student Phone</p>

                                    <p style={{ flex: 0.5, fontWeight: '600' }}>:</p>
                                    <input
                                        style={{ flex: 4 }}
                                        type="text"
                                        placeholder={StudentPhoneError ? StudentPhoneError : 'Phone no'}
                                        value={StudentPhone || ''}
                                        onChange={txt => {
                                            const value = txt.target.value.replace(/\D/g, "");
                                            setStudentPhone(value)
                                        }}
                                    />
                                </div>

                                <div className="student-dtl-textinput">
                                    <p style={{ flex: 2, fontWeight: '600', color: EmailError ? 'red' : 'black' }}>Student Email</p>
                                    <p style={{ flex: 0.5, fontWeight: '600' }}>:</p>
                                    <input
                                        style={{ flex: 4 }}
                                        type="email"
                                        placeholder={EmailError ? EmailError : 'Email'}
                                        value={StudentEmail || ''}
                                        onChange={txt => setStudentEmail(txt.target.value)}
                                    />
                                </div>

                                <div className="student-dtl-textinput">
                                    {/* <p style={{ flex: 2, fontWeight: '600' }}>Parent Phone</p> */}
                                    <p style={{ flex: 2, fontWeight: '600', color: ParentPhoneError ? 'red' : 'black' }}>Parent Phone</p>

                                    <p style={{ flex: 0.5, fontWeight: '600' }}>:</p>
                                    <input
                                        style={{ flex: 4 }}
                                        type="text"
                                        // placeholder="Parent Phone"
                                        placeholder={ParentPhoneError ? ParentPhoneError : 'Parent phone'}

                                        value={StudentParentPhone || ''}
                                        onChange={txt => {
                                            const value = txt.target.value.replace(/\D/g, "");
                                            setStudentParentPhone(value)
                                        }}
                                    />
                                </div>

                                <div className="student-dtl-textinput">
                                    {/* <p style={{ flex: 2, fontWeight: '600' }}>Whatsapp Number</p> */}
                                    <p style={{ flex: 2, fontWeight: '600', color: WhatsaapError ? 'red' : 'black' }}>Whatsapp Number</p>

                                    <p style={{ flex: 0.5, fontWeight: '600' }}>:</p>
                                    <input
                                        style={{ flex: 4 }}
                                        type="text"
                                        placeholder={WhatsaapError ? WhatsaapError : ' Whatsapp Number '}
                                        value={StudentWhatsapp || ''}
                                        onChange={txt => {
                                            const value = txt.target.value.replace(/\D/g, "");
                                            setStudentWhatsapp(value)
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Second Box */}

                            <div className="student-dtl-box-course stud-grid-item2">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <p style={{ fontSize: 16, fontWeight: 'bold' }}>Course</p>
                                    {
                                        state && (
                                        <div>
                                            <input 
                                                checked={SeatConveted || ''} 
                                                type="checkbox" 
                                                onChange={(txt) => {
                                                    setSeatConveted(!SeatConveted)
                                                }}
                                            />
                                            <label>Converted</label>
                                        </div>
                                        )
                                    }
                                    
                                </div>
                                
                                <select
                                    disabled={state ? true : false}
                                    value={College || ''}
                                    onChange={txt => {
                                        setCollege(txt.target.value)
                                        fetch_batches(txt.target.value)
                                    }}
                                    className="student-select-option"
                                >
                                    <option value={''}>Choose College</option>
                                    {
                                        CollegeData.map((item, key) => {
                                            const { id, name } = item;
                                            return (
                                                <option key={key} value={id}>{name}</option>
                                            )
                                        })
                                    }
                                </select>

                                <select
                                    disabled={state ? true : false}
                                    value={AcdYr!==null ? AcdYr : ''}
                                    onChange={txt => {
                                        setAcdYr(txt.target.value)
                                    }}
                                    className="student-select-option"
                                >
                                    {state ?
                                        <option value={''}>{AcdYr}</option>
                                    :
                                        <option value={''}>Choose Academic Year</option>
                                    }
                                    
                                    {
                                        college_acd_yr_list.map((item, key) => {
                                            const { id, year } = item;
                                            return (
                                                <option key={key} selected={key===0} value={id}>{year}</option>
                                            )
                                        })
                                    }
                                </select>

                                <select
                                    disabled={state ? true : false}
                                    value={Batch || ''}
                                    onChange={txt => {
                                        setBatch(txt.target.value);
                                        if (txt.target.value) {
                                            const { course, duration, duration_type } = BatchData.find(item => item.id === Number(txt.target.value));
                                            fetch_addon_courses(course);
                                            setFeeStucture(duration, duration_type);
                                            setCourse(course);
                                        }
                                    }}
                                    className="student-select-option"
                                >
                                    <option value={''}>Choose Batch</option>
                                    {
                                        BatchData.map((item, key) => {
                                            const { id, name } = item
                                            return (
                                                <option key={key} value={id}>{name}</option>
                                            )
                                        })
                                    }
                                </select>

                                <select
                                    value={AddOnCourse || ''}
                                    onChange={txt => {
                                        setAddOnCourse(txt.target.value)
                                    }}
                                    className="student-select-option"
                                >
                                    <option value="">Choose Ad-On Course</option>
                                    {
                                        AddonCourseData.map((item, key) => {
                                            const { id, name } = item;
                                            return (
                                                <option key={key} value={id}>{name}</option>
                                            )
                                        })
                                    }
                                </select>

                                <select
                                    value={SeatType || ''}
                                    onChange={txt => {
                                        setSeatType(txt.target.value)
                                        setSeatConveted(!SeatConveted)
                                    }}
                                    className="student-select-option"
                                >
                                    <option value={''}>Choose Seat</option>
                                    <option value={'merit'}>Merit</option>
                                    <option value={'management'}>Management</option>
                                </select>

                                <div
                                    onClick={() => setAddonModal(true)}
                                    style={{ color: 'grey', fontSize: 12, marginTop: 20, display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                    className={Course ? '' : 'disabled-btn'}>
                                    <AddCircleOutline style={{ fontSize: 16 }} />
                                    <p> Add Ad-On Course</p>
                                </div>
                            </div>

                            {/* Third Box */}

                            <div className="student-dtl-box stud-grid-item3">
                                <p style={{ fontSize: 16, fontWeight: 'bold' }}>Agency</p>
                                <select
                                    value={Agency || ''}
                                    onChange={txt => setAgency(txt.target.value)}
                                    className="student-select-option"
                                >
                                    <option value={''}>EBK (Direct)</option>
                                    {
                                        AgencyData.map((item, key) => {
                                            const { id, consultant_name, consultancy_name } = item
                                            return (
                                                <option key={key} value={id}>{consultant_name} ({consultancy_name})</option>
                                            )
                                        })
                                    }
                                </select>

                                <p style={{ fontSize: 16, fontWeight: 'bold', marginTop: 10 }}>Financial Year</p>
                                <select
                                    value={FinancialYr || ''}
                                    onChange={txt => setFinancialYr(txt.target.value)}
                                    className="student-select-option"
                                >
                                    <option value={''}>Choose Financial Year</option>
                                    {
                                        FinancialYrData.map((item, key) => {
                                            const { id, year } = item
                                            return (
                                                <option key={key} value={id}>{year}</option>
                                            )
                                        })
                                    }
                                </select>

                                <p style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20 }}>Remarks</p>
                                <textarea
                                    value={Remarks || ''}
                                    onChange={txt => setRemarks(txt.target.value)}
                                    style={{ width: '100%', height: 100, marginTop: 10 }}
                                />
                            </div>

                            {/* Fourth Box */}

                            <div className="student-dtl-box-finance stud-grid-item4">
                                <Scrollbars style={{ height: '36vh', width: '100%' }}>
                                    <p style={{ fontSize: 16, fontWeight: 'bold' }}>Fees / Finance</p>
                                    {/* Cleared */}
                                    <div className="student-detail-finance">
                                        {StudentFees && StudentFees.map((item, key) => {
                                            const { amount, name } = item;
                                            let addonCourse_statues = '';
                                            if (AddOnCourse === '' || AddOnCourse === null) { // response from addoncouse is null 
                                                addonCourse_statues = '';
                                            } else {
                                                addonCourse_statues = AddOnCourse;
                                            }
                                            if(name!=='Addon Course')
                                            return (
                                                <div key={key} className="student-detail-finance-box">
                                                    <p style={{ marginLeft: 5 }}>{name}</p>
                                                    <input
                                                        type="number"
                                                        // disabled={StudentFees.length - 1 === key ? true : false}
                                                        disabled={name === 'Addon Course' && addonCourse_statues === '' ? true : false}
                                                        placeholder="00"
                                                        value={amount || ''}
                                                        style={{ paddingLeft: 10 }}
                                                        onChange={txt => {

                                                            let target_amount = txt.target.value;
                                                            if (!Number(target_amount)) {
                                                                target_amount = '';
                                                            } else {
                                                                target_amount = Number(target_amount);
                                                            }

                                                            let newArr = StudentFees.map((item, i) => {
                                                                if (key === i) {
                                                                    return { ...item, amount: target_amount };
                                                                } else {
                                                                    return item;
                                                                }
                                                            });

                                                            let total = 0;
                                                            for (let i = 0; i < newArr.length - 1; i++) {
                                                                total = total + Number(newArr[i].amount);
                                                            }

                                                            let newArr2 = newArr.map((item, i) => {
                                                                if (i === newArr.length - 1) {
                                                                    return { ...item, amount: total };
                                                                } else {
                                                                    return item;
                                                                }
                                                            });
                                                            setStudentFees(newArr2);
                                                        }}
                                                    />
                                                </div>
                                            )
                                            else return null
                                        })}
                                    </div>
                                </Scrollbars>
                            </div>

                            {/* All Box Ends */}

                        </div>
                    </div>





                    <div className="section-2">
                        <div className="student-dtl-box-layout">
                            {/* Cleared */}
                            <div className={state ? 'student-dtl-box-small' : 'student-dtl-box-small disabled-btn-none'}>
                                <p style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 10 }}>Payment</p>

                                {!student_due_loader ? (
                                    <>
                                        <p>Current Due {`till ${arr_student_fee_general_info.current_duration} :`} <span style={{ fontWeight: 'bold', fontSize: 14 }}> {arr_student_fee_general_info.current_due || 0}</span></p>

                                        <div style={{ marginBottom: 10, marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}>
                                            { arr_students_fees.length > 0 ? <p style={{ fontSize: 16 }}>History</p> : null}
                                            <p
                                                onClick={() => {
                                                    setModal(true);
                                                }}
                                                // className={arr_students_fees.length > 0 ? '' : 'disabled-btn'}
                                                style={{ fontSize: 12 }}>More</p>
                                        </div>
                                        {state && (
                                            arr_students_fees.map((item, key) => {
                                                if (key < 6) {
                                                    const { amount, updated_at, id } = item;
                                                    let date_val = new Date(updated_at);
                                                    let date_z = dayjs(date_val).format('DD/MM/YYYY');
                                                    return (
                                                        <div key={key} style={{ display: 'flex', alignItems: 'center', height: 30 }}>
                                                            <p style={{ flex: 1 }}>{key + 1}.</p>
                                                            <p style={{ flex: 2 }}>{amount}</p>
                                                            <p style={{ flex: 2 }}>{date_z}</p>
                                                            <button
                                                                onClick={() => individual_recipt_print_fx(id)}
                                                                style={{
                                                                    flex: 1,
                                                                    height: 20,
                                                                    width: 90,
                                                                    fontSize: 12,
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    cursor: 'pointer',
                                                                    borderRadius: 30,
                                                                    border: 0,
                                                                    backgroundColor: 'var(--cl_violet)',
                                                                    color: '#fff',
                                                                    pointerEvents: PrintButtonLoader ? 'none' : '',
                                                                    opacity: PrintButtonLoader ? 0.4 : 1
                                                                }}>
                                                                {
                                                                    PrintButtonLoader ? (
                                                                        <CircularProgress size={14} color="inherit" />
                                                                    ) : (
                                                                        'Print'
                                                                    )
                                                                }
                                                            </button>
                                                        </div>
                                                    )
                                                } else return null;
                                            })
                                        )}
                                    </>
                                ) : (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100 }}>
                                        <CircularProgress size={30} />
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* Cleared */}
                        <div className="student-dtl-box-layout">
                            <div className={state ? 'student-dtl-box-small' : 'student-dtl-box-small disabled-btn-none'}>
                                <p style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 10 }}>Fee Recipt</p>
                                <input
                                    type="number"
                                    className="student-select-option"
                                    placeholder="Amount"
                                    value={FeeAmount || ''}
                                    onChange={txt => setFeeAmount(txt.target.value)}
                                />

                                <input 
                                    checked={isAdmissionFee} 
                                    type="checkbox" 
                                    onChange={(txt) => {
                                        setIsAdmissionFee(!isAdmissionFee)
                                    }}
                                />
                                <label>Admission Fee</label>

                                <input
                                    type="text"
                                    className="student-select-option"
                                    placeholder="Description"
                                    value={FeeDescription || ''}
                                    onChange={txt => setFeeDescription(txt.target.value)}
                                />

                                <input
                                    type="text"
                                    className="student-select-option"
                                    placeholder="Collection Place"
                                    value={FeeCollectionPlace || ''}
                                    onChange={txt => setFeeCollectionPlace(txt.target.value)}
                                />
                                
                                <select
                                    value={FeeModePay || ''}
                                    onChange={txt => setFeeModePay(txt.target.value)}
                                    className="student-select-option"
                                >
                                    <option value="">Choose Mode</option>
                                    <option value="Cash">Cash</option>
                                    <option value="UPI">UPI</option>
                                    <option value="Cheque">Cheque</option>
                                    <option value="Bank Transfer">Bank Transfer</option>
                                </select>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}>

                                    {
                                        fees_add_btn_status ? (
                                            <button
                                                className={buttonLoader ? "submit-btn disabled-btn" : "submit-btn"}
                                                onClick={() => { create_fee_recipt_fx() }}>
                                                {buttonLoader ? (<CircularProgress size={20} color="inherit" />) : ('Add Fees')}
                                            </button>
                                        ) : (<button className="submit-btn disabled-btn"> Add Fees</button>)
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <TransactionModal
                modalStatus={Modal}
                modalAction={setModal}
                data={arr_students_fees}
            // scrolling={ScrollFeesTransactions}
            />
            <AddonModalRender
                modalStatus={AddonModal}
                modalAction={setAddonModal}
                AddonCourseName={AddonCourseName}
                setAddonCourseName={setAddonCourseName}
                buttonClick={create_addon_course_fx}
                buttonLoader={buttonLoader}
                setButtonLoader={setButtonLoader}
            />
        </div>
    )
}

export default Detail
