import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import NavbarAgency from '../components/navbar_home'
import ListAgency from '../components/list_agency'
import AgentModal from '../components/agent_modal'
import { fetch_agencies, fetch_colleges } from '../store/action'
import { fetch_financial_years } from '../../finance/store/action'


function Home(props) {

    const [modal, setModal] = useState(false)

    const [AgentName, setAgentName] = useState('');
    const [AgencyName, setAgencyName] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [SecPhoneNumber, setSecPhoneNumber] = useState('');
    const [Email, setEmail] = useState('');
    const [EmailError, setEmailError] = useState('');
    const [DueAmount, setDueAmount] = useState('');

    const route_history = props.history
    const dispatch = useDispatch();

    useEffect(() => {
        fetch_colleges(route_history, dispatch)
        fetch_agencies(route_history, dispatch, 1)
        fetch_financial_years(route_history, dispatch);
    }, [route_history, dispatch])


    return (
        <div className="app-dynamic-layout">
            <NavbarAgency modalAction={setModal} />
            <ListAgency modalAction={setModal} />
            <AgentModal
                modalStatus={modal}
                modalAction={setModal}
                AgentName={AgentName}
                setAgentName={setAgentName}
                AgencyName={AgencyName}
                setAgencyName={setAgencyName}
                PhoneNumber={PhoneNumber}
                setPhoneNumber={setPhoneNumber}
                SecPhoneNumber={SecPhoneNumber}
                setSecPhoneNumber={setSecPhoneNumber}
                DueAmount={DueAmount}
                setDueAmount={setDueAmount}
                Email={Email}
                setEmail={setEmail}
                EmailError={EmailError}
                setEmailError={setEmailError}
            />
        </div>
    )
}

export default Home
