import React, { useState } from 'react';
import Scrollview from 'react-custom-scrollbars';
import { useSelector, useDispatch } from 'react-redux'
import { fetch_commission } from '../store/action'
import { selectcommissionDatas } from '../store/slice'
import { CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import StaffModal from './commission_modal'

function ListAgency() {

    const route_history = useHistory();
    const dispatch = useDispatch();

    let commission_data = useSelector(selectcommissionDatas)
    const { arr_commission, commission_loader, commission_list_loader, commission_page, commission_pagination } = commission_data;

    const [Modal, setModal] = useState(false);
    const [SelectedCommission, setSelectedCommission] = useState({});
    const [StudentName, setStudentName] = useState('');
    const [AgentName, setAgentName] = useState('');
    const [Agency, setAgency] = useState('');

    const [College, setCollege] = useState('');
    const [Course, setCourse] = useState('');
    const [Batch, setBatch] = useState('');
    const [BatchId, setBatchId] = useState('');

    const [Amount, setAmount] = useState('');
    const [StuFees, setStuFees] = useState('');

    const ScrollPos = (val) => {
        const { scrollHeight, scrollTop, clientHeight } = val.target;
        let bottom = scrollHeight - scrollTop === clientHeight
        if (bottom) {
            if (commission_pagination) {
                let page_no_val = commission_page + 1;
                fetch_commission(route_history, dispatch, page_no_val, arr_commission, '', '', false, true);
            }
        }
    }


    return (
        <div className="app-main-list-layout">
            <div className="app-table-layout">

                <table className="app_table_main">
                    <tbody className="app_table_tbody">
                        <tr style={{ fontWeight: 'bold', fontSize: 12 }}>
                            {/* <td style={{ width: 120 }}>Student ID</td> */}
                            <td style={{ width: 120 }}>Student Name</td>
                            <td style={{ width: 120 }}>Agent</td>
                            <td style={{ width: 120 }}>College</td>
                            <td style={{ width: 120 }}>Course</td>
                            <td style={{ width: 120 }}>Fees Due 1 & 2</td>
                            <td style={{ width: 120 }}>Total Student Fees</td>
                            <td style={{ width: 120 }}>Amount</td>
                            <td style={{ width: 120 }}>Manage</td>
                        </tr>
                    </tbody>
                </table>

                <Scrollview
                    onScroll={e => ScrollPos(e)}
                    style={{ height: '70vh', position: 'relative' }}
                >
                    {
                        !commission_loader ? (
                            <>
                                {
                                    arr_commission.length > 0 ? (
                                        <>
                                        <table className="app_table_main">
                                            <tbody className="app_table_tbody">


                                                {

                                                    arr_commission.map((item, key) => {
                                                        const { name, first_sem_fee_cleared, second_sem_fee_cleared, agent_name, college_name, course_name, agent_commission, student_fees_total, batch, batch_name, agency } = item;
                                                        return (
                                                            <tr key={key} className="app-table-row"
                                                            style={{ opacity : item.active_students ? '1' : '.5' }}
                                                            >
                                                                {/* <td style={{ width: 120, padding: 0 }}>ST{id}</td> */}
                                                                <td style={{ width: 120, padding: 0 }}>{name}</td>
                                                                <td style={{ width: 120, padding: 0 }}>{agent_name}</td>
                                                                <td style={{ width: 120, padding: 0 }}>{college_name}</td>
                                                                <td style={{ width: 120, padding: 0 }}>{course_name}</td>
                                                                <td style={{ width: 120, padding: 0 }}>
                                                                    <div style={{ display: 'flex', justifyContent: 'space-evenly', fontSize: 18 }}>
                                                                        {first_sem_fee_cleared ? (<p>✅</p>) : (<p>❌</p>)}
                                                                        {second_sem_fee_cleared ? (<p>✅</p>) : (<p>❌</p>)}
                                                                    </div>
                                                                </td>
                                                                <td style={{ width: 120, padding: 0 }}>{student_fees_total}</td>
                                                                <td style={{ width: 120, padding: 0 }}>{agent_commission || 0}</td>
                                                                <td style={{ width: 120, padding: 0 }}>
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <button
                                                                            onClick={() => {
                                                                                setSelectedCommission(item);
                                                                                setStudentName(name);
                                                                                setAgentName(agent_name);
                                                                                setAgency(agency);
                                                                                setCollege(college_name);
                                                                                setCourse(course_name);
                                                                                setAmount(agent_commission || 0);
                                                                                setBatch(batch_name);
                                                                                setBatchId(batch);
                                                                                setStuFees(student_fees_total);
                                                                                setModal(true);
                                                                            }}
                                                                            style={{ fontWeight: 'bold', fontSize: 12, cursor: 'pointer' }} > Update</button>
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        )
                                                    })


                                                }
                                            </tbody>
                                        </table>
                                        {
                                            commission_list_loader && (
                                                <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
                                                    <CircularProgress size={30} />
                                                </div>
                                            )
                                        }
                                        </>
                                    ) : (
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                                            <p style={{ fontSize: 14, fontWeight: 'bold' }}>No Data Found</p>
                                        </div>
                                    )
                                }

                            </>

                            /* {
                    commission_pagination && (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress size={20} />
                        </div>
                    )
                }
             */
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                                <CircularProgress size={26} />
                            </div>
                        )
                    }

                    <StaffModal

                        modalStatus={Modal}
                        modalAction={setModal}
                        data={SelectedCommission}
                        StudentName={StudentName}
                        setStudentName={setStudentName}
                        AgentName={AgentName}
                        Agency={Agency}
                        setAgency={setAgency}

                        setAgentName={setAgentName}
                        College={College}
                        setCollege={setCollege}
                        Course={Course}
                        setCourse={setCourse}
                        Amount={Amount}
                        setAmount={setAmount}
                        Batch={Batch}
                        setBatch={setBatch}
                        BatchId={BatchId}
                        setBatchId={setBatchId}
                        StuFees={StuFees}
                        setStuFees={setStuFees}
                    />


                </Scrollview>
            </div>
        </div>
    )
}

export default ListAgency
