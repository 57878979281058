import React from 'react'
import { PersonAdd, Search } from '@material-ui/icons'
import Navbar from '../../../common-components/navbar';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { fetch_staffs } from '../store/action';

function NavbarAgency({ modalStatus }) {

    const route_history = useHistory();
    const dispatch = useDispatch();

    return (
        <Navbar>
            <h4>Staff</h4>
            <div className="app-nav-right">
                <button
                    onClick={() => modalStatus(true)}
                    className="app-common-btn">
                    <PersonAdd style={{ fontSize: 16, marginRight: 10 }} />Add
                </button>

                <div className="app-nav-searchbar">
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={txt => {
                            let keyword = txt.target.value;
                            if (keyword) {
                                fetch_staffs(route_history, dispatch, 1, [], keyword);
                            } else {
                                fetch_staffs(route_history, dispatch, 1, [], '');
                            }
                        }}
                    />
                    <Search style={{ fontSize: 18 }} />

                </div>

            </div>
        </Navbar>
    )
}

export default NavbarAgency
