import Api from '../../../store/api';
import { ACADEMIC_YEAR_CRUD, BASE_MAIN, COLLEGE_ADDON_COURSE_BASE, COLLEGE_BASE, COLLEGE_COURSE_BASE, FEES_BASE, STUDENT_ACTIVE_STATUS, STUDENT_ALL_FEE_RECIPT, STUDENT_ALL_PRINT, STUDENT_BASE, STUDENT_INDIVIDUAL_FEE_RECIPT } from '../../../store/endpoint';
import { 
    update_staudents, 
    update_student_due_fees, 
    add_single_student, 
    remove_single_student, 
    update_single_student, 
    update_students_loader, 
    update_students_due_loader,
    update_all_courses, 
    update_students_listbottom_loader,
    update_all_addon_courses} from '../store/slice';
import { update_colleges } from '../../college/store/slice';
import Swal from 'sweetalert2';
import { FileDownloader } from '../../../store/file_downloader';
// import dayjs from 'dayjs';

export const fetch_students = (history, dispatch, page_no, prev_data, filter = "all", search = '', seat_type = "all", date = '', course = 'all', addonCourse = 'all', loader = true, listLoader = false, batch = 'all') => {
    let api_url = `${STUDENT_BASE}?page=${page_no}&limit=20`;
    if (search) {
        api_url = `${api_url}&key=${search}`;
    }
    if (filter !== 'all') {
        api_url = `${api_url}&college_id=${filter}`;
    }
    if (course !== 'all') {
        api_url = `${api_url}&course_id=${course}`;
    }
    if (seat_type !== 'all') {
        if(seat_type === 'inactive') {
            api_url = `${api_url}&status=${seat_type}`;
        } else {
            api_url = `${api_url}&seat_type=${seat_type}`;
        }
    }
    if (date !== "") {
        api_url = `${api_url}&date=${date}`;
    }
    if(addonCourse !== 'all') {
        api_url = `${api_url}&addon_course=${addonCourse}`;
    }
    if(batch !== 'all'){
        api_url = `${api_url}&batch_id=${batch}`
    }

    dispatch(update_students_loader({ loader: loader }))
    dispatch(update_students_listbottom_loader({ loader: listLoader }))
    Api('get', api_url, '', history).then(res => {
        const { response, statusCode } = res
        if (statusCode === 200) {
            const { total_student, direct_students, agency_students, records } = response;
            let final_data = page_no > 1 ? prev_data.concat(records) : records
            let pagination_status;
            if (records.length < 20) {
                pagination_status = false;
            } else if (records.length === 20) {
                pagination_status = true;
            }
            dispatch(update_staudents({
                arr_response: final_data,
                total_students: total_student,
                direct_students: direct_students,
                agency_students: agency_students,
                page_no,
                pagination: pagination_status,
                search,
                filter,
                date,
                seat_type,
                course,
                addonCourse,
                batch
            }))
        } else {
            dispatch(update_students_loader({ loader: false }))
        }

    })
}


export const fetch_colleges = (history, dispatch, collegeAcdYr = null) => {  //FOR FILTER
    let api_url = collegeAcdYr!==null ? `${COLLEGE_BASE}?page=1&year=${collegeAcdYr}&limit=60`
    :  `${COLLEGE_BASE}?page=1&limit=60`
    Api('get', api_url, '', history).then(res => {
        const { response, statusCode } = res
        if(statusCode === 200) {
            dispatch(update_colleges({
                arr_response: response,
                academic_yr : collegeAcdYr
            }))
        }
        
    })
}


export const fetch_all_courses = (history, dispatch) => {  //FOR FILTER
    let api_url = `${COLLEGE_COURSE_BASE}?page=1&limit=100`
    Api('get', api_url, '', history).then(res => {
        const { response, statusCode } = res
        if(statusCode === 200) {
            dispatch(update_all_courses({
                arr_response: response
            }))
        }
        
    })
}


export const fetch_all_addon_courses = (history, dispatch) => {  //FOR FILTER
    let api_url = `${COLLEGE_ADDON_COURSE_BASE}?page=1&limit=100`
    Api('get', api_url, '', history).then(res => {
        const { response, statusCode } = res
        if(statusCode === 200) {
            dispatch(update_all_addon_courses({
                arr_response: response
            }))
        }
        
    })
}


export const create_student = (history, dispatch, post_data, success, failed) => {
    Api('post', STUDENT_BASE, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Student Created Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(add_single_student({
                new_data: res.response
            }));
        }else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })
        }

    })
}


export const update_student = (history, dispatch, post_data, student_id, success, failed) => {
    let api_url = STUDENT_BASE + student_id + '/';
    Api('put', api_url, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Student Details Updated',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(update_single_student({
                student_id: student_id,
                new_data: res.response
            }))
        }else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })
        }
    })
}


export const delete_student = (history, dispatch, student_id) => {
    let api_url = STUDENT_BASE + student_id + '/';
    Api('delete', api_url, '', history).then(res => {
        if (res.statusCode === 200) {
            Swal.fire(
                'Deleted!',
                'Student deleted!',
                'success'
            )
            dispatch(remove_single_student({
                student_id: student_id,
            }))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data,
            })
        }
    })
}


export const update_student_active_status = (history, student_id, status, date, success, failed) => {  //FOR FILTER
    let api_url = `${STUDENT_ACTIVE_STATUS}${student_id}/?activation_date=${date}&student_status=${status}`
    Api('get', api_url, '', history).then(res => {
        const { statusCode } = res
        if(statusCode === 200) {
            success()
        } else {
            if(failed)
                failed();
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        }
        
    })
}


export const create_addon_course = (history, post_data, success, failed) => {
    Api('post', COLLEGE_ADDON_COURSE_BASE, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Addon Course Created Successfully',
                showConfirmButton: false,
                timer: 1500
            })
        }else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })
        }

    })
}

export const create_fee_recipt = (history, dispatch, post_data, success, failed) => {
    Api('post', FEES_BASE, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Fee Recipt Created Successfully',
                showConfirmButton: false,
                timer: 1500
            })
        }else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })
        }

    })
}


export const fetch_student_due_fees = (history, dispatch, student_id, loader = true) => {
    let api_url = `${BASE_MAIN}fees/crud/due/${student_id}/`;
    dispatch(update_students_due_loader({ loader: loader }))
    Api('get', api_url, '', history).then(res => {
        const { response, statusCode } = res
        if (statusCode === 200) {
            const { records, general_info } = response;
            dispatch(update_student_due_fees({
                arr_response: records,
                general_info: general_info,
            }))
        } else {
            dispatch(update_students_due_loader({ loader: false }))
            dispatch(update_student_due_fees({
                arr_response: [],
                general_info: {
                    current_due: 0,
                    current_duration: "",
                    paid_amount: 0,
                    total: 0,
                    total_due: 0
                },
            }))
        }
    })
}


export const print_individual_fee_recipt = (history, fees_transaction_id, sucess, failed) => {
    let api_url = `${STUDENT_INDIVIDUAL_FEE_RECIPT}${fees_transaction_id}/`

    Api('file-download', api_url, '', history).then(res => {
        
        const { statusCode, response } = res;
        if (statusCode === 200) {
            sucess();
            FileDownloader(response, 'fee_recipt_');
        } else {
            if(failed)
            failed();
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.error_app,
            })
        }
    })
}


export const print_all_fee_recipt = (history, student_id, sucess, failed) => {
    let api_url = `${STUDENT_ALL_FEE_RECIPT}${student_id}/`

    Api('file-download', api_url, '', history).then(res => {
        
        const { statusCode, response } = res;
        if (statusCode === 200) {
            sucess();
            FileDownloader(response, 'fee_transactions_');
        } else {
            if(failed)
                failed();
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.error_app,
            })
        }
    })
}

export const print_students_all_data = (history, filter = "all", search = '', seat_type = "all", date = '', course = 'all', addonCourse = 'all', success, failed) => {
    
    let api_url = `${STUDENT_ALL_PRINT}?`;
    if (search) {
        api_url = `${api_url}key=${search}&`;
    }
    if (filter !== 'all') {
        api_url = `${api_url}college_id=${filter}&`;
    }
    if (course !== 'all') {
        api_url = `${api_url}course=${course}&`;
    }
    if (seat_type !== 'all') {
        if(seat_type === 'inactive') {
            api_url = `${api_url}status=${seat_type}&`;
        } else {
            api_url = `${api_url}seat_type=${seat_type}&`;
        }
    }
    if (date !== "") {
        api_url = `${api_url}&date=${date}&`;
    }
    if(addonCourse !== 'all') {
        api_url = `${api_url}&addon_course=${addonCourse}&`;
    }

   

    Api('file-download', api_url, '', history).then(res => {
        
        const { statusCode, response } = res;
        if (statusCode === 200) {
            success();
            FileDownloader(response, 'fees_');
        } else {
            if(failed)
                failed();
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.error_app,
            })
        }
    })
}

export const add_academic_yr = (history, post_data, success, failed) => {
    Api('post', ACADEMIC_YEAR_CRUD, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Academic Year Created Successfully',
                showConfirmButton: false,
                timer: 1500
            })
        }else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })
        }

    })
}