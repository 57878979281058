import Api from '../../../store/api';
import { FEES_BASE, FEES_PRINT } from '../../../store/endpoint';
import { update_fees, add_single_fees, remove_single_fees, update_fees_loader, update_fees_list_loader } from '../store/slice';
import Swal from 'sweetalert2';
import { FileDownloader } from '../../../store/file_downloader';


export const fetch_fees = (history, dispatch, page_no, prev_data, search = '', filter = 'all', filter_from_date = '', filter_to_date = '', loader = true, listLoader = false) => {
    let api_url = `${FEES_BASE}?page=${page_no}&limit=20`

    if (search) {
        api_url = `${api_url}&key=${search}`
    }
    if (filter !== 'all') {
        api_url = `${api_url}&college=${filter}`
    }

    if (filter_from_date && filter_to_date) {
        api_url = `${api_url}&from=${filter_from_date}&to=${filter_to_date}`;
    }

    dispatch(update_fees_loader({ loader: loader }));
    dispatch(update_fees_list_loader({ loader: listLoader }));

    Api('get', api_url, '', history).then(res => {
        const { response, statusCode } = res
        if (statusCode === 200) {
            const { general_info, records } = response;
            let final_data = page_no > 1 ? prev_data.concat(records) : records
            let pagination_status;
            if (records.length < 20) {
                pagination_status = false;
            } else if (records.length === 20) {
                pagination_status = true;
            }
            dispatch(update_fees({
                arr_response: final_data,
                general_infos: general_info,
                page_no,
                pagination: pagination_status,
                search,
                filter,
                filter_from_date,
                filter_to_date,
            }))
        } else {
            dispatch(update_fees_loader({ loader: false }))
        }
    })
}


export const create_fees = (history, dispatch, post_data, success, failed) => {

    Api('post', FEES_BASE, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Fees Added Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(add_single_fees({
                new_data: res.response
            }));
        }
        else {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data,
            })

        }

    })
}


// export const update_student = (history, dispatch, post_data, student_id, success) => {
//     let api_url = STUDENT_BASE + student_id+ '/';
//     Api('put', api_url, post_data, history, success).then(res => {
//         if(res.statusCode === 200) {
//             Swal.fire({
//                 position: 'top-end',
//                 icon: 'success',
//                 title: 'Student Details Updated',
//                 showConfirmButton: false,
//                 timer: 1500
//               })
//             dispatch(update_single_student({
//                 student_id: student_id,
//                 new_data: res.response
//             }))
//         }
//     })
// }


export const delete_fees = (history, dispatch, fees_id) => {
    let api_url = FEES_BASE + fees_id + '/';
    Api('delete', api_url, '', history).then(res => {
        if (res.statusCode === 200) {
            Swal.fire(
                'Deleted!',
                'Student Fee deleted!',
                'success'
            )
            dispatch(remove_single_fees({
                fees_id: fees_id,
            }))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.error_app,
            })
        }
    })
}


export const print_fees = (history, filter = 'all', filter_from_date = '', filter_to_date = '', success, failed) => {
    let api_url = `${FEES_PRINT}?`

    if (filter !== 'all') {
        api_url = `${api_url}college=${filter}&`
    }

    if (filter_from_date && filter_to_date) {
        api_url = `${api_url}from=${filter_from_date}&to=${filter_to_date}&`;
    }

    Api('file-download', api_url, '', history).then(res => {
        
        const { statusCode, response } = res;
        if (statusCode === 200) {
            success();
            FileDownloader(response, 'fees_');
        } else {
            if(failed)
                failed();
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.error_app,
            })
        }
    })
}