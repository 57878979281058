import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { create_college, update_college } from '../store/action';
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '95%',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function CollegeModal({
    modalStatus,
    modalAction,
    data,
    Name,
    setName,
    CordinatorName,
    setCordinatorName,
    CordinatorPhone,
    setCordinatorPhone,
    CordinatorEmail,
    setCordinatorEmail,
    EmailError,
    setEmailError
}) {



    const classes = useStyles();

    const handleClose = () => {
        modalAction(false)
    };

    let router_history = useHistory()
    let dispatch = useDispatch()
    const [buttonLoader, setButtonLoader] = useState(false);
    const [PhoneError, setPhoneError] = useState('');




    let college_name = '';
    let college_cordinator = '';
    let cordinator_phone = '';
    let cordinator_email = '';

    if (data) {
        college_name = data.name;
        college_cordinator = data.coordinator_name;
        cordinator_phone = data.coordinator_phone;
        cordinator_email = data.email;
    }



    const button_fx = () => {

        setButtonLoader(true);


        const success = () => {
            setButtonLoader(false);
            setName('')
            setCordinatorName('')
            setCordinatorEmail('')
            setCordinatorPhone('')
            setEmailError('')
            setPhoneError('')
            handleClose()
        }
        const failed = () => {
            setButtonLoader(false);
            setName('')
            setCordinatorName('')
            setCordinatorEmail('')
            setCordinatorPhone('')
            setEmailError('')
            setPhoneError('')
            handleClose()
        }

        if (CordinatorEmail) {
            const email_regex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
            const phoneno = new RegExp(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/);

            let email_status = email_regex.test(CordinatorEmail);
            let phone_status = phoneno.test(CordinatorPhone);

            if (email_status && phone_status) {
                let postData = {
                    name: Name,
                    coordinator_name: CordinatorName,
                    coordinator_phone: CordinatorPhone,
                    email: CordinatorEmail
                }

                setEmailError('')
                if (data) {
                    // console.log('redy for update api call', postData)
                    update_college(router_history, dispatch, postData, data.id, success, failed)
                } else {
                    // console.log('redy for create api call', postData)
                    create_college(router_history, dispatch, postData, success, failed)
                }


            } else {
                email_status ? setEmailError('') : setEmailError('Invalid Email Id')
                phone_status ? setPhoneError('') : setPhoneError('Invalid number')
                setButtonLoader(false)
            }
        } else {
            let postData = {
                name: Name,
                coordinator_name: CordinatorName,
                coordinator_phone: CordinatorPhone
            }

            if (data) {
                // console.log('redy for update api call', postData)
                update_college(router_history, dispatch, postData, data.id, success, failed)
            } else {
                // console.log('redy for create api call', postData)
                create_college(router_history, dispatch, postData, success, failed)
            }
        }
    }

    let add_btn_status = false;
    if (Name && CordinatorName && CordinatorPhone) {
        add_btn_status = true;
    }

    let update_btn_status = false;
    if (Name !== college_name || CordinatorName !== college_cordinator || CordinatorPhone !== cordinator_phone || CordinatorEmail !== cordinator_email) {
        update_btn_status = true
    }

    let general_button = false;
    data ? general_button = update_btn_status : general_button = add_btn_status


    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >

            <div className="app-modal-main">
                <h4 style={{ margin: 0, marginBottom: 20 }}>{data ? 'Update' : 'Create'} College</h4>
                <form className={classes.root} noValidate autoComplete="off">

                    <TextField
                        id="standard-basic"
                        label="College Name"
                        value={Name}
                        helperText="*"
                        onChange={txt => setName(txt.target.value)}
                    />

                    <TextField
                        id="standard-basic"
                        label="Coordinator Name"
                        value={CordinatorName}
                        onChange={txt => setCordinatorName(txt.target.value)}
                    />

                    <TextField
                        error={PhoneError ? true : false}
                        id="standard-basic"
                        label="Coordinator Phone"
                        type="tel"
                        value={CordinatorPhone}
                        onChange={txt => {
                            const value = txt.target.value.replace(/\D/g, "");
                            setCordinatorPhone(value)
                        }}
                        helperText={PhoneError}

                    />

                    <TextField
                        error={EmailError ? true : false}
                        id="standard-basic"
                        label="Coordinator Email"
                        type="email"
                        value={CordinatorEmail}
                        onChange={txt => setCordinatorEmail(txt.target.value)}
                        helperText={EmailError}
                    />

                </form>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {general_button ? (
                        <button
                            onClick={() => button_fx()}
                            className={buttonLoader ? "submit-btn disabled-btn" : "submit-btn"} >
                            {buttonLoader ? (<CircularProgress size={20} color="inherit" />) : (data ? 'Update' : 'Create')}

                        </button>
                    ) : (<button className="submit-btn disabled-btn">{data ? 'Update' : 'Create'}</button>)
                    }
                </div>

            </div>
        </Modal>
    );
}



export default CollegeModal
