import React from 'react'

function Navbar({ children }) {
    return (
        <div className="navbar-main">
            {children}
        </div>
    )
}

export default Navbar
