import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { create_staff, update_staff } from '../store/action'
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { selectCollegeDatas } from '../../college/store/slice';
import { CircularProgress } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '95%',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function StaffModal({
    modalStatus,
    modalAction,
    data,
    Name,
    setName,
    Designation,
    setDesignation,
    PhoneNo,
    setPhoneNo,
    Email,
    setEmail,
    College,
    setCollege,
    Password,
    setPassword,
    EmailError,
    setEmailError
}) {

    const classes = useStyles();
    const handleClose = () => {
        modalAction(false)
    };

    const router_history = useHistory();
    const dispatch = useDispatch();
    const { arr_colleges } = useSelector(selectCollegeDatas);
    const [buttonLoader, setbuttonLoader] = useState(false);
    const [PhoneError, setPhoneError] = useState('');



    let name = ''
    let designation = ''
    let phone_no = '';
    let email = ''
    let college = ''

    if (data) {
        name = data.name;
        designation = data.user_type
        phone_no = data.primary_phone
        email = data.email
        college = data.college_id
    }


    const button_fx = () => {

        setbuttonLoader(true);

        const success = () => {
            setName('');
            setDesignation('admin');
            setPhoneNo('');
            setEmail('');
            setCollege('');
            setPassword('');
            setEmailError('');
            handleClose();
            setbuttonLoader(false);

        }

        const failed = () => {

            handleClose();
            setEmail('');
            setPassword('');
            setbuttonLoader(false);



        }

        if (Email) {
            const email_regex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
            const phoneno = new RegExp(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/);

            let email_status = email_regex.test(Email);
            let phone_status = phoneno.test(PhoneNo);

            if (email_status && phone_status) {
                let postData = {
                    name: Name,
                    primary_phone: PhoneNo,
                    user_type: Designation,
                    email: Email,
                    password: Password
                }
                if (College) {
                    postData.college_id = College
                }
                setEmailError('');
                if (data) {
                    delete postData['password'];
                    // console.log('redy for update api call', postData)
                    update_staff(router_history, dispatch, postData, data.id, success, failed)
                } else {
                    // console.log('redy for create api call', postData)
                    create_staff(router_history, dispatch, postData, success, failed)
                }


            } else {
                setbuttonLoader(false);
                email_status ? setEmailError('') : setEmailError('Invalid Email Id')
                phone_status ? setPhoneError('') : setPhoneError('Invalid number')

            }
        }
    }

    let add_btn_status = false;
    if (Name && PhoneNo && Designation && Email && PhoneNo && Password) {
        if (Designation === 'college-admin' || Designation === 'college-fee-manager') {
            if (College) {
                add_btn_status = true;
            }
        } else {
            add_btn_status = true;
        }

    }

    let update_btn_status = false;
    if (Name !== name || PhoneNo !== phone_no || Designation !== designation || Email !== email || College !== college || PhoneNo !== phone_no) {
        update_btn_status = true;
    }

    let general_button = false;
    data ? general_button = update_btn_status : general_button = add_btn_status

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >

            <div className="app-modal-main">
                <h4 style={{ margin: 0, marginBottom: 20 }}>Create Staff</h4>

                <form className={classes.root} noValidate autoComplete="off">
                    <TextField
                        id="standard-basic"
                        label="Name"
                        value={Name}
                        onChange={txt => setName(txt.target.value)}
                    />

                    <TextField
                        id="standard-basic"
                        select
                        label="Designation"
                        value={Designation}
                        onChange={txt => setDesignation(txt.target.value)}
                    >
                        <MenuItem value={'admin'}>Admin</MenuItem>
                        <MenuItem value={'finance-admin'}>Finance Manager</MenuItem>
                        <MenuItem value={'college-admin'}>College Admin</MenuItem>
                        <MenuItem value={'college-fee-manager'}>College Fee Manager</MenuItem>
                        <MenuItem value={'surveil-admin'}>Surveillance Admin</MenuItem>
                    </TextField>

                    {
                        Designation === 'college-admin' || Designation === 'college-fee-manager' ? (
                            <TextField
                                id="standard-basic"
                                select
                                label="College"
                                value={College}
                                onChange={txt => setCollege(txt.target.value)}
                            >
                                <MenuItem value=''>Choose College</MenuItem>
                                {
                                    arr_colleges.map((item, key) => {
                                        const { id, name } = item;
                                        return (
                                            <MenuItem key={key} value={id}>{name}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        ) : (null)
                    }



                    <TextField
                        error={PhoneError ? true : false}
                        id="standard-basic"
                        label="Phone No"
                        type="tel"
                        value={PhoneNo}
                        onChange={txt => {
                            const value = txt.target.value.replace(/\D/g, "");
                            setPhoneNo(value)
                        }}
                        helperText={PhoneError}

                    />

                    <TextField
                        error={EmailError ? true : false}
                        id="standard-basic"
                        label="Email"
                        type="email"
                        value={Email}
                        onChange={txt => setEmail(txt.target.value)}
                        helperText={EmailError}
                    />

                    <TextField
                        id="standard-basic"
                        label="Username"
                        disabled
                        value={PhoneNo}
                    />
                    {
                        !data && (
                            <TextField
                                id="standard-basic"
                                label="Password"
                                type="password"
                                value={Password}
                                onChange={txt => setPassword(txt.target.value)}
                            />
                        )
                    }


                </form>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* <button className="submit-btn">Create</button> */}
                    {general_button ? (
                        <button
                            onClick={() => button_fx()}
                            className={buttonLoader ? "submit-btn disabled-btn" : "submit-btn"} >
                            {buttonLoader ? (<CircularProgress size={20} color="inherit" />) : (data ? 'Update' : 'Create')}

                        </button>
                    ) : (<button className="submit-btn disabled-btn">{data ? 'Update' : 'Create'}</button>)
                    }
                </div>

            </div>
        </Modal>
    );
}



export default StaffModal
