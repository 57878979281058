import React, { useEffect, useState } from 'react';
import Scrollview from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { selectFinanceDatas } from '../store/slice';
import { delete_category_action, fetch_finance, delete_finance, delete_sub_category_action, fetch_financial_years, 
    //delete_financial_years 
} from '../store/action';
import { useHistory } from 'react-router';
import { AddCircleRounded, Delete, Edit, ExpandLessRounded, ExpandMoreRounded, } from '@material-ui/icons';
import CategoryModal from '../components/category_modal';
import SubCategoryModal from '../components/sub_category_modal';
import Swal from 'sweetalert2';
import { CircularProgress } from '@material-ui/core';
import FinanceYrModal from './financial_yr_modal';
import { selectAgencyDatas } from '../../agency/store/slice';

function ListAgency({
    setSelectedFinance,
    modalStatus,
    setAmount,
    setDescription,
    setType,
    setCategory,
    setModePay,
    setSubCategory,
    setAgencyId,
    setFinanceYrId,
    setSearch
}) {

    const route_history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        fetch_financial_years(route_history, dispatch);   
    }, [route_history, dispatch]);
    const {
        arr_finance,
        finance_loader,
        finance_list_loader,
        total_expense,
        total_income,
        arr_category,
        arr_sub_category,
        finance_page,
        finance_pagination,
        finance_search,
        finance_filter_type,
        finance_filter_category,
        finance_filter_from_date,
        finance_filter_to_date,
        financialYrList,
        finance_filter_year

    } = useSelector(selectFinanceDatas);
    const { all_agencts_list } = useSelector(selectAgencyDatas);
    const [Modal, setModal] = useState(false)
    const [SModal, setSModal] = useState(false)
    const [YModal, setYModal] = useState(false)
    const [CategoryName, setCategoryName] = useState('')
    const [SelectedCategory, setSelectedCategory] = useState('');
    const [expandSection, setExpandSection] = useState(0)

    const [yearStart, setYearStart] = useState('')
    const [yearEnd, setYearEnd] = useState('')
    const delete_category_fx = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                delete_category_action(route_history, dispatch, id)
            }
        })
    }

    const delete_sub_category_fx = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                delete_sub_category_action(route_history, dispatch, id)
            }
        })
    }

    // const delete_financialYr_fx = (id) => {
    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: "You won't be able to revert this!",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes, delete it!'
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             delete_financial_years(route_history, dispatch, id)
    //         }
    //     })
    // }

    const delete_single_finance = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                delete_finance(route_history, dispatch, id)
            }
        })
    }

    const ScrollPos = (val) => {
        const { scrollHeight, scrollTop, clientHeight } = val.target;
        let bottom = scrollHeight - scrollTop === clientHeight
        if (bottom) {
            if (finance_pagination) {
                let page_no_val = finance_page + 1;
                fetch_finance(
                    route_history,
                    dispatch,
                    page_no_val,
                    arr_finance,
                    finance_search,
                    finance_filter_type,
                    finance_filter_category,
                    finance_filter_from_date,
                    finance_filter_to_date,
                    false,
                    false,
                    finance_filter_year
                )
            }
        }
    }


    return (
        <div style={{ display: 'flex' }} className="app-main-list-layout">
            <div className="app-table-layout">
                <table className="app_table_main">
                    <tbody className="app_table_tbody">
                        <tr style={{ fontWeight: 'bold', fontSize: 12 }}>
                            <td style={{ width: 60, padding: 0 }}>TRN ID</td>
                            <td style={{ width: 95, padding: 0 }}>Date</td>
                            <td style={{ width: 120, padding: 0 }}>Amount</td>
                            <td style={{}}>Description</td>
                            <td style={{ width: 120, padding: 0 }}>Finance Type</td>
                            <td style={{ width: 120, padding: 0 }}>Category</td>
                            <td style={{ width: 120, padding: 0 }}>Sub Category</td>
                            <td style={{ width: 100, padding: 0 }}>Mode of pay</td>
                            <td style={{ width: 100, padding: 0 }}>Action</td>
                        </tr>
                    </tbody>
                </table>

                <Scrollview onScroll={ScrollPos} style={{ height: '70vh', position: 'relative' }}>
                    {!finance_loader ? (
                        <>
                            {arr_finance.length > 0 ? (
                                <>
                                <table className="app_table_main">
                                    <tbody className="app_table_tbody">
                                        {
                                            arr_finance.map((item, key) => {
                                                const { id, amount, description, finance_type, category_name, sub_category_name, category_type, sub_category_type, mode_of_payment, updated_at } = item
                                                let date_val = new Date(updated_at);
                                                let date_dz = date_val.getDate() + '-' + (date_val.getMonth()+1) + '-' + date_val.getFullYear();
                                                return (
                                                    <tr key={key} className="app-table-row">
                                                        <td style={{ width: 60, padding: 0 }}>TR{id}</td>
                                                        <td style={{ width: 95, padding: 0 }}>{date_dz}</td>
                                                        <td style={{ width: 120, padding: 0 }}>{amount}</td>
                                                        <td style={{}}>{description}</td>
                                                        <td style={{ width: 120, padding: 0 }}>{finance_type}</td>
                                                        <td style={{ width: 120, padding: 0 }}>{category_name}</td>
                                                        <td style={{ width: 120, padding: 0 }}>{sub_category_name}</td>
                                                        <td style={{ width: 100, padding: 0 }}>{mode_of_payment}</td>
                                                        <td style={{ width: 100, padding: 0 }}>
                                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Edit
                                                                    style={{ fontSize: 16, marginRight: 10, cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        setSelectedFinance(item)
                                                                        setAmount(amount)
                                                                        setDescription(description)
                                                                        setType(finance_type)
                                                                        setCategory(category_type)
                                                                        setModePay(mode_of_payment)
                                                                        setSubCategory(sub_category_type)

                                                                        setAgencyId(item.agency_id ? item.agency_id : '')
                                                                        setSearch(item.agency_id && all_agencts_list ? all_agencts_list.find(el => el.id===parseInt(item.agency_id)) ?
                                                                            all_agencts_list.find(el => el.id===parseInt(item.agency_id)).consultant_name : '' : '' )
                                                                        setFinanceYrId(item.financial_year ? item.financial_year : null)
                                                                        modalStatus(true)
                                                                    }}
                                                                />
                                                                <Delete onClick={() => delete_single_finance(id)} style={{ fontSize: 16, marginLeft: 10, cursor: 'pointer' }} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                {
                                    finance_list_loader && (
                                        <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
                                            <CircularProgress size={30} />
                                        </div>
                                    )
                                }
                                </>
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                                    <p style={{ fontSize: 14, fontWeight: 'bold' }}>No Data Found</p>
                                </div>
                            )}
                        </>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                            <CircularProgress size={26} />
                        </div>
                    )}

                </Scrollview>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: 248 }}>
                <div
                    style={{
                        backgroundColor: '#CDCDCD',
                        marginLeft: 10,
                        height: 180,
                        width: 248,
                        borderRadius: 20,
                        fontSize: 14,
                        padding: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <p>Total Income</p>
                    <p style={{ fontWeight: 'bold', fontSize: 14 }}>{(total_income !== null) ? total_income.toFixed(2) : 0}</p>

                    <p style={{ marginTop: 20 }}>Total Expense</p>
                    <p style={{ fontWeight: 'bold', fontSize: 14 }}>{(total_expense !== null) ? total_expense.toFixed(2) : 0} </p>

                </div>

                <div
                    style={{
                        backgroundColor: '#CDCDCD',
                        marginLeft: 10,
                        marginTop: 20,
                        padding: 10,
                        width: 248,
                        borderRadius: 20,
                        fontSize: 14,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Scrollview style={{ height: 400, width: '100%' }}>
                        <div style={{ display: 'flex', width: '98%', justifyContent: 'space-between' }}>
                            <p style={{ fontWeight: 'bold', display:'flex', alignItems: 'center', cursor:'pointer'  }}
                                onClick={() => setExpandSection(expandSection===0 ? 1 : 0) }
                            >Financial Years
                            {
                                expandSection===0 ?
                                    <ExpandLessRounded style={{ fontSize:'1.5em' }} />
                                :
                                    <ExpandMoreRounded style={{ fontSize:'1.5em' }}  />
                            }
                            </p>
                            <AddCircleRounded onClick={() => {
                                setSModal(false)
                                setModal(false)
                                setYModal(true)
                            }} />
                        </div>
                        {   expandSection===0 ?
                            financialYrList.map((item, key) => {
                                const { year } = item;
                                return (
                                    <div key={key} style={{ display: 'flex', width: '98%', marginTop: 10, padding: 5, borderRadius: 5, backgroundColor: '#c2c1be', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <p style={{ flex: 0.2 }}>{key + 1}.</p>
                                        <p style={{ flex: 2 }}>{year}</p>
                                        <Edit
                                            onClick={() => {
                                                setSelectedCategory(item);
                                                setYearStart(year)
                                                setSModal(false);
                                                setModal(false);
                                                setYModal(true)
                                            }}
                                            style={{ fontSize: 18, marginRight: 10, cursor: 'pointer' }} />
                                        {/* <Delete onClick={() =>{
                                            delete_financialYr_fx(id)
                                            }} style={{ fontSize: 18, marginRight: 10, cursor: 'pointer' }} /> */}
                                    </div>
                                )
                            })
                            : null
                        }


                        <div style={{ display: 'flex', width: '98%', justifyContent: 'space-between' }}>
                            <p style={{ fontWeight: 'bold', display:'flex', alignItems: 'center', cursor:'pointer' }}
                            onClick={() => setExpandSection(expandSection===1 ? 2 : 1) }>Finance Categories
                            {
                                expandSection===1 ?
                                    <ExpandLessRounded style={{ fontSize:'1.5em' }} />
                                :
                                    <ExpandMoreRounded style={{ fontSize:'1.5em' }}  />
                            }
                            </p>
                            <AddCircleRounded onClick={() => {
                                setSModal(false)
                                setModal(true)
                                setYModal(false)
                            }} />
                        </div>
                        {
                            expandSection===1 ?
                            arr_category.map((item, key) => {
                                const { id, name } = item;
                                return (
                                    <div key={key} style={{ display: 'flex', width: '98%', marginTop: 10, padding: 5, borderRadius: 5, backgroundColor: '#c2c1be', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <p style={{ flex: 0.2 }}>{key + 1}.</p>
                                        <p style={{ flex: 2 }}>{name}</p>
                                        <Edit
                                            onClick={() => {
                                                setSelectedCategory(item);
                                                setCategoryName(name);
                                                setSModal(false);
                                                setModal(true);
                                                setYModal(false)
                                            }}
                                            style={{ fontSize: 18, marginRight: 10, cursor: 'pointer' }} />
                                        <Delete onClick={() => delete_category_fx(id)} style={{ fontSize: 18, marginRight: 10, cursor: 'pointer' }} />
                                    </div>
                                )
                            })
                            : null
                        }


                        <div style={{ display: 'flex', width: '98%', marginTop: expandSection===0 ? 'auto' : 50, justifyContent: 'space-between' }}>
                            <p style={{ fontWeight: 'bold', display:'flex', alignItems: 'center', cursor:'pointer' }}
                            onClick={() => setExpandSection(expandSection===2 ? 0 : 2) }>Finance Sub Categories
                            {
                                expandSection===2 ?
                                    <ExpandLessRounded style={{ fontSize:'1.5em' }} />
                                :
                                    <ExpandMoreRounded style={{ fontSize:'1.5em' }}  />
                            }
                            </p>
                            <AddCircleRounded onClick={() => {
                                setModal(false)
                                setSModal(true)
                                setYModal(false)
                            }} />
                        </div>
                        {
                            expandSection===2 ?
                            arr_sub_category.map((item, key) => {
                                const { id, name } = item;
                                return (
                                    <div key={key} style={{ display: 'flex', width: '95%', marginTop: 10, padding: 5, borderRadius: 5, backgroundColor: '#c2c1be', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <p style={{ flex: 0.2 }}>{key + 1}.</p>
                                        <p style={{ flex: 2 }}>{name}</p>
                                        <Edit
                                            onClick={() => {
                                                setSelectedCategory(item);
                                                setCategoryName(name);
                                                setModal(false);
                                                setSModal(true);
                                                setYModal(false)
                                            }}
                                            style={{ fontSize: 18, marginRight: 10, cursor: 'pointer' }} />
                                        <Delete onClick={() => delete_sub_category_fx(id)} style={{ fontSize: 18, marginRight: 10, cursor: 'pointer' }} />
                                    </div>
                                )
                            })
                            : null
                        }
                    </Scrollview>


                </div>
            </div>
            <CategoryModal
                modalStatus={Modal}
                modalAction={setModal}
                data={SelectedCategory}
                CategoryName={CategoryName}
                setCategoryName={setCategoryName}
            />
            <SubCategoryModal
                modalStatus={SModal}
                modalAction={setSModal}
                data={SelectedCategory}
                CategoryName={CategoryName}
                setCategoryName={setCategoryName}
            />
            <FinanceYrModal
                modalStatus={YModal}
                modalAction={setYModal}
                data={SelectedCategory}
                yearStart={yearStart}
                setYearStart={setYearStart}
                yearEnd={yearEnd}
                setYearEnd={setYearEnd}
            />
        </div>
    )
}

export default ListAgency
