import React, { Fragment, useState } from 'react';
import { ArrowBack, PersonAdd, Search } from '@material-ui/icons';
import Navbar from '../../../common-components/navbar';
import { Link, useHistory, useLocation } from 'react-router-dom';
import CourseModal from './course_modal';
import BatchModal from './batch_modal';
import { selectPermissionDatas } from '../../../common-components/store/slice';
import { fetch_college_courses, fetch_college_batches, fetch_college_students, fetch_colleges } from '../store/action';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { selectCollegeDatas } from '../store/slice';
//import { total_student, direct_students, agency_students, fetch_college_students } from '../store/action';

const useStyles = makeStyles((theme) => ({
    formControl: {
        //   margin: theme.spacing(1),
        minWidth: 160,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    resize: {
        fontSize: 14,
        fontWeight: 500,
        color: 'black'
    }
}));


function NavbarAgency() {

    const route_history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { pathname, state } = useLocation();
    const { current_user_type } = useSelector(selectPermissionDatas);
    const { college_total_students, college_direct_students, college_agency_students, college_acd_yr, college_batch_course_filter, college_acd_yr_list } = useSelector(selectCollegeDatas);

    let pathname_students = '/college/detail/students'
    let pathname_course = '/college/detail/courses'
    let pathname_batch = '/college/detail/batch';

    const [Modal, setModal] = useState(false);
    const [College, setCollege] = useState(state ? state.college_id : '');
    // const [CollegeName, setCollegeName] = useState(state ? state.college_name : '');

    const [Name, setName] = useState('')
    const [BatchName, setBatchName] = useState('')

    const [GraduationType, setGraduationType] = useState('')
    const [DurationType, setDurationType] = useState('')
    const [Duration, setDuration] = useState('')
    const [openStatus, setOpenStatus] = useState(false)

    let current_date = new Date();
    let date_month = current_date.getMonth() < 10 ? '0' + current_date.getMonth() : current_date.getMonth();
    let date_dat = current_date.getDate() < 10 ? '0' + current_date.getDate() : current_date.getDate();
    let date_final = current_date.getFullYear() + '-' + date_month + '-' + date_dat


    const [Course, setCourse] = useState('')
    const [BatchFrom, setBatchFrom] = useState(date_final)
    const [BatchTo, setBatchTo] = useState(date_final)





    return (
        <Navbar>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link to="/college" className="app-nav-back-btn">
                    <ArrowBack />
                </Link>
                <h5>{state && state.college_name}</h5>
            </div>

            {
                pathname === pathname_students ? (
                    <div style={{ display: 'flex', flex: 1 }}>
                        <p style={{ fontSize: 14, marginLeft: 30 }}>Total: <span style={{ fontWeight: 'bold' }}>{college_total_students}</span></p>
                        <p style={{ fontSize: 14, marginLeft: 30 }}>Direct: <span style={{ fontWeight: 'bold' }}>{college_direct_students}</span></p>
                        <p style={{ fontSize: 14, marginLeft: 20 }}>Agency: <span style={{ fontWeight: 'bold' }}>{college_agency_students}</span></p>
                    </div>
                ) : (null)
            }
            

            <div className="app-nav-right">

                {
                    current_user_type === 'superadmin' || current_user_type === 'admin' ? (
                        <Fragment>
                            {
                                pathname === pathname_course || pathname === pathname_batch ? (
                                    <button onClick={() => setModal(true)} className="app-common-btn"><PersonAdd style={{ fontSize: 16, marginRight: 10 }} />Add</button>
                                ) : (null)
                            }
                        </Fragment>
                    ) : (null)
                }
                <div style={{ margin: '0px 10px' }}>

                    <FormControl className={classes.formControl}>
                        <Select
                            disableUnderline
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            SelectDisplayProps={{ fontSize: 14 }}
                            open={openStatus}
                            value={
                                college_acd_yr ? college_acd_yr : 'all'
                            }
                            className={classes.resize}
                            onClick={()=> setOpenStatus(!openStatus) }
                            onChange={txt => {
                                let filterVal = null
                                console.log(txt.target.value)
                                if(txt.target.value!=='all')
                                    filterVal = txt.target.value
                                else 
                                    filterVal = null
                                fetch_colleges(route_history, dispatch, 1, [], '', 20, true, filterVal  )
                                fetch_college_students(route_history, dispatch, state.college_id, 1, [], '',true, filterVal)
                                fetch_college_courses(route_history, dispatch, state.college_id, 1, [], '',true, filterVal)
                                fetch_college_batches(route_history, dispatch, state.college_id, 1, [], '',true, college_batch_course_filter, filterVal)
                                setOpenStatus(false)
                            }}
                        >
                            <MenuItem value={'all'}>Filter - All</MenuItem>
                            {
                                
                                college_acd_yr_list && college_acd_yr_list.map((item, key) => {
                                    const { id, year } = item;
                                return (
                                    <MenuItem key={key} value={id} style={{ display : 'flex', flexDirection : 'row', justifyContent : 'space-between' }}>
                                        <span>{year}</span>
                                        <span name='acdYrEditOpts' onClick={(e)=>{
                                                setOpenStatus(false)
                                                e.stopPropagation(); }}>
                                            {/* <Edit onClick={()=> { 
                                                setyeardata(item); setYearData(year); setYrModal(true);
                                                
                                                }}  style={{ fontSize: 16, marginRight: 10 }}/> */}
                                            {/* <Delete onClick={() => delete_fx(id) } style={{ fontSize: 16, marginRight: 10 }} /> */}
                                        </span>
                                    </MenuItem>
                                )
                                })

                            }
                        </Select>
                    </FormControl>
                </div>

                <div className="app-nav-searchbar">
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={txt => {
                            let keyword = txt.target.value;
                            if (pathname === pathname_course) {
                                if (keyword) fetch_college_courses(route_history, dispatch, state.college_id, 1, [], keyword, true, college_acd_yr);
                                else fetch_college_courses(route_history, dispatch, state.college_id, 1, [], '',true, college_acd_yr);

                            } else if (pathname === pathname_batch) {
                                fetch_college_courses(route_history, dispatch, state.college_id, 1, [], '',true, college_acd_yr)
                                if (keyword) fetch_college_batches(route_history, dispatch, state.college_id, 1, [], keyword, true, college_batch_course_filter, college_acd_yr);
                                else fetch_college_batches(route_history, dispatch, state.college_id, 1, [], '',true, college_batch_course_filter, college_acd_yr);

                            } else if (pathname === pathname_students) {
                                if (keyword) fetch_college_students(route_history, dispatch, state.college_id, 1, [], keyword, true, college_acd_yr);
                                else fetch_college_students(route_history, dispatch, state.college_id, 1, [], '',true, college_acd_yr);
                            }
                        }}
                    />
                    <Search style={{ fontSize: 18 }} />
                </div>

            </div>

            {
                pathname === pathname_course && (
                    <CourseModal
                        modalStatus={Modal}
                        modalAction={setModal}
                        College={College}
                        setCollege={setCollege}
                        Name={Name}
                        setName={setName}
                        GraduationType={GraduationType}
                        setGraduationType={setGraduationType}
                        DurationType={DurationType}
                        setDurationType={setDurationType}
                        Duration={Duration}
                        setDuration={setDuration}
                    />
                )
            }

            {
                pathname === pathname_batch && (
                    <BatchModal
                        modalStatus={Modal}
                        modalAction={setModal}
                        BatchName={BatchName}
                        setBatchName={setBatchName}
                        Course={Course}
                        setCourse={setCourse}
                        BatchFrom={BatchFrom}
                        setBatchFrom={setBatchFrom}
                        BatchTo={BatchTo}
                        setBatchTo={setBatchTo}
                        // CollegeName={CollegeName}
                        // setCollegeName={setCollegeName}
                        DurationType={DurationType}
                        Duration={Duration}
                        date_final={date_final}
                    />
                )
            }

        </Navbar>
    )
}

export default NavbarAgency
