import { createSlice } from '@reduxjs/toolkit';

export const staffSlice = createSlice({
    name: 'staff',
    initialState: {
        arr_staff: [],
        arr_staff_category: [],
        staff_page: 1,
        staff_search: '',
        staff_pagination: true,
        staff_loader: false,
    },
    reducers: {
        update_staffs: (state, action) => {
            const { arr_response, page_no, pagination, search } = action.payload;
            state.arr_staff = arr_response;
            state.staff_page = page_no;
            state.staff_pagination = pagination;
            state.staff_search = search;
            state.staff_loader = false;
        },
        update_staff_loader: (state, action) => {
            const { loader } = action.payload;
            state.staff_loader = loader;
        },
        add_single_staff: (state, action) => {
            const { new_data } = action.payload;
            state.arr_staff.unshift(new_data);
        },

        remove_single_staff: (state, action) => {
            const { staff_id } = action.payload;
            let latest_list = state.arr_staff.filter(item => item.id !== staff_id);
            state.arr_staff = latest_list;
        },

        update_single_staff: (state, action) => {
            const { staff_id, new_data } = action.payload;
            let array_item = state.arr_staff.find(item => item.id === staff_id);
            let arra_item_index = state.arr_staff.indexOf(array_item);
            state.arr_staff[arra_item_index] = new_data;
        },
        update_single_staff_status: (state, action) => {
            const { staff_id, new_data } = action.payload;
            let array_item = state.arr_staff.find(item => item.id === staff_id);
            let arra_item_index = state.arr_staff.indexOf(array_item);
            state.arr_staff[arra_item_index] = new_data;
        },
    }
})

export const {
    update_staffs,
    update_staff_loader,
    add_single_staff,
    remove_single_staff,
    update_single_staff,
    update_single_staff_status,

} = staffSlice.actions

export const selectStaffDatas = state => state.staff;

export default staffSlice.reducer;