import React from 'react';
import { CircularProgress } from '@material-ui/core';
import Scrollview from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { selectCollegeDatas } from '../store/slice';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { fetch_college_students } from '../store/action';

function TabStudentsList() {

    const { arr_students, college_students_loader, college_students_page, college_students_pagination, college_students_search, college_acd_yr } = useSelector(selectCollegeDatas);

    const route_history = useHistory();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const ScrollPos = (val) => {
        const { scrollHeight, scrollTop, clientHeight } = val.target;
        let bottom = scrollHeight - scrollTop === clientHeight
        if (bottom) {
            if (college_students_pagination) {
                let page_no_val = college_students_page + 1;
                fetch_college_students(route_history, dispatch, state.college_id, page_no_val, arr_students, college_students_search, false, college_acd_yr);
            }

        }
    }

    return (
        <div style={{ padding: '0px', margin: 0, height: '72vh' }} className="app-main-list-layout">
            <div className="app-table-layout">

                <table className="app_table_main">
                    <tbody className="app_table_tbody">
                        <tr style={{ fontWeight: 'bold', fontSize: 12 }}>
                            <td style={{ width: 80 }}>Student ID</td>
                            <td style={{ width: 100}}>Student Name</td>
                            <td style={{ width: 150 }}>Course</td>
                            <td style={{ width: 150 }}>Batch</td>
                            <td style={{ width: 150 }}>Agent</td>
                            <td style={{ width: 150 }}>Contact</td>
                            <td style={{ width: 150 }}>Email</td>
                        </tr>
                    </tbody>
                </table>

                <Scrollview
                    onScroll={e => ScrollPos(e)}
                    style={{ height: '68vh', position: 'relative' }}>
                    {!college_students_loader ? (
                        <>
                            {arr_students.length > 0 ? (
                                <table className="app_table_main">
                                    <tbody className="app_table_tbody">
                                        {
                                            arr_students.map((item, key) => {
                                                const { id, name, batch_name, course_name, agent_name, student_contact, email } = item;
                                                return (
                                                    <tr key={key} className="app-table-row">
                                                        <td style={{ width: 80 }}>ST{id}</td>
                                                        <td style={{ width: 100}}>{name}</td>
                                                        <td style={{ width: 150 }}>{course_name}</td>
                                                        <td style={{ width: 150 }}>{batch_name}</td>
                                                        <td style={{ width: 150 }}>{agent_name}</td>
                                                        <td style={{ width: 150 }}>{student_contact}</td>
                                                        <td style={{ width: 150 }}>{email}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                                    <p style={{ fontSize: 14, fontWeight: 'bold' }}>No Data Found</p>
                                </div>
                            )}
                        </>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                            <CircularProgress size={26} />
                        </div>
                    )}
                </Scrollview>
            </div>
        </div>
    )
}

export default TabStudentsList
