//let BASE = "https://deep.ebkstudy.test.centrric.com/";
//let BASE = "https://deep.ebkstudy.in/"
let BASE = 'https://staging-webservices.ebkstudy.in/';
// let BASE = 'https://webservices.ebkstudy.in/';               //NEW PRODUCTION

const API_CONFI = 'api/v1/'
export const BASE_MAIN = BASE + API_CONFI

export const AUTHENTICATION = BASE + 'api/token/';
export const REFRESH_AUTHENTICATION = AUTHENTICATION + 'refresh/';

export const AGENCY_BASE =  BASE_MAIN + 'agency/crud/';
    export const AGENCY_TRANSACTION_PRINT = BASE_MAIN + 'prints/agent/transaction/';
export const STAFF_BASE = BASE_MAIN + 'staff/crud/';
export const COLLEGE_BASE = BASE_MAIN + 'college/crud/';
    //export const COLLEGE_STUDENT_BASE = BASE_MAIN + 'college/course/crud/';
    export const COLLEGE_COURSE_BASE = BASE_MAIN + 'college/course/crud/';
    export const COLLEGE_ADDON_COURSE_BASE = BASE_MAIN + 'college/course/addon/crud/';
    export const COLLEGE_BATCH_BASE = BASE_MAIN + 'college/course/batch/crud/';

export const STUDENT_BASE = BASE_MAIN + 'student/crud/';
    export const STUDENT_ACTIVE_STATUS = BASE_MAIN + 'student/status/';
    export const STUDENT_INDIVIDUAL_FEE_RECIPT = BASE_MAIN + 'prints/fees/'
    export const STUDENT_ALL_PRINT = BASE_MAIN + 'student/export/scsv/';
    export const STUDENT_ALL_FEE_RECIPT = BASE_MAIN + 'prints/fees/all/student/'
export const FEES_BASE = BASE_MAIN + 'fees/crud/';
    export const FEES_PRINT = BASE_MAIN + 'prints/fees/';
export const FINANCE_BASE = BASE_MAIN + 'finance/crud/';
    export const FINANCE_CATEGORY_BASE = BASE_MAIN + 'finance/category/crud/';
    export const FINANCE_SUB_CATEGORY_BASE = BASE_MAIN + 'finance/category/crud/sub/';
    export const FINANCE_PRINT = BASE_MAIN + 'prints/finance/';
export const COMMISSION_BASE = BASE_MAIN + 'commission/crud/';

export const FINANCIAL_YEAR_CRUD = BASE_MAIN + 'finance/crud/financialyear/'
export const ACADEMIC_YEAR_CRUD = BASE_MAIN + 'college/crud/academicyear/'
