import React, { useState } from 'react';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import Scrollview from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { selectCollegeDatas } from '../store/slice';
import CollegeModal from '../components/college_modal';
import { delete_college, fetch_colleges } from '../store/action';
import { CircularProgress } from '@material-ui/core';

function ListCollege() {

    const { arr_colleges, college_loader, college_page, college_pagination, college_search, college_acd_yr } = useSelector(selectCollegeDatas);

    const route_history = useHistory();
    const dispatch = useDispatch();

    const [Modal, setModal] = useState(false);
    const [SelectedCollege, setSelectedCollege] = useState({});
    const [Name, setName] = useState('');
    const [CordinatorName, setCordinatorName] = useState('');
    const [CordinatorPhone, setCordinatorPhone] = useState('');
    const [CordinatorEmail, setCordinatorEmail] = useState('');
    const [EmailError, setEmailError] = useState('');

    const delete_fx = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                delete_college(route_history, dispatch, id);
            }
        })
    }

    const ScrollPos = (val) => {
        const { scrollHeight, scrollTop, clientHeight } = val.target;
        let bottom = scrollHeight - scrollTop === clientHeight
        if (bottom) {
            if (college_pagination) {
                let page_no_val = college_page + 1;
                fetch_colleges(route_history, dispatch, page_no_val, arr_colleges, college_search, null, false, college_acd_yr);
            }

        }
    }

    return (
        <div className="app-main-list-layout">
            <div className="app-table-layout">

                <table className="app_table_main">
                    <tbody className="app_table_tbody">
                        <tr style={{ fontWeight: 'bold', fontSize: 12 }}>
                            <td style={{ width: 80 }}>College Id</td>
                            <td style={{ width: 150 }}>College Name</td>
                            <td style={{ width: 150 }}>Coordinator</td>
                            <td style={{ width: 150 }}>Cordinator Phone</td>
                            <td style={{ width: 150 }}>Cordinator Email</td>
                            <td style={{ width: 150 }}>Total Students</td>
                            <td style={{ width: 200 }}>Manage</td>
                        </tr>
                    </tbody>
                </table>

                <Scrollview
                    onScroll={e => ScrollPos(e)}
                    style={{ height: '70vh', position: 'relative' }}>
                    {!college_loader ? (
                        <>
                            {arr_colleges.length > 0 ? (
                                <table className="app_table_main">
                                    <tbody className="app_table_tbody">
                                        {
                                            arr_colleges.map((item, key) => {
                                                const { id, name, coordinator_name, coordinator_phone, email, total_students } = item;
                                                return (
                                                    <tr key={key} className="app-table-row">
                                                        <td style={{ width: 80 }}>Cl{id}</td>
                                                        <td style={{ width: 150 }}>{name}</td>
                                                        <td style={{ width: 150 }}>{coordinator_name}</td>
                                                        <td style={{ width: 150 }}>{coordinator_phone}</td>
                                                        <td style={{ width: 150 }}>{email}</td>
                                                        <td style={{ width: 150 }}>{total_students}</td>
                                                        <td style={{ width: 200 }}>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <div onClick={() => route_history.push('/college/detail', { college_id: id, college_name: name })} >
                                                                    <Visibility
                                                                        //onClick={() => modalStatus(true)} 
                                                                        style={{ fontSize: 16, marginRight: 20, cursor: 'pointer' }} />
                                                                </div>

                                                                <Edit
                                                                    onClick={() => {
                                                                        setSelectedCollege(item);
                                                                        setName(name);
                                                                        setCordinatorName(coordinator_name);
                                                                        setCordinatorPhone(coordinator_phone);
                                                                        setCordinatorEmail(email);
                                                                        setModal(true);
                                                                    }}
                                                                    style={{ fontSize: 16, marginRight: 20, cursor: 'pointer' }} />
                                                                <Delete onClick={() => delete_fx(id)} style={{ fontSize: 16, marginRight: 20, cursor: 'pointer' }} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                                    <p style={{ fontSize: 14, fontWeight: 'bold' }}>No Data Found</p>
                                </div>
                            )}
                        </>

                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                            <CircularProgress size={26} />
                        </div>
                    )}
                </Scrollview>
            </div>

            <CollegeModal
                modalStatus={Modal}
                modalAction={setModal}
                data={SelectedCollege}
                Name={Name}
                setName={setName}
                CordinatorName={CordinatorName}
                setCordinatorName={setCordinatorName}
                CordinatorPhone={CordinatorPhone}
                setCordinatorPhone={setCordinatorPhone}
                CordinatorEmail={CordinatorEmail}
                setCordinatorEmail={setCordinatorEmail}
                EmailError={EmailError}
                setEmailError={setEmailError}
            />

        </div>
    )
}

export default ListCollege
