import React, { useEffect, useState } from 'react';
import Scrollview from 'react-custom-scrollbars';
import { CircularProgress } from '@material-ui/core';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Swal from 'sweetalert2';
import { delete_trasaction_action, fetch_transactions } from '../store/action';
import { selectAgencyDatas } from '../store/slice';
import { STUDENT_BASE } from '../../../store/endpoint';
import Api from '../../../store/api';


function ListAgency({ setSelectedTransaction, setAmount, setModeofpay, setModal ,  
    setfinancialYear,
    }) {

    const { 
        arr_transaction, 
        transaction_loader, 
        transaction_list_loader, 
        transaction_page, 
        transaction_pagination, 
        transaction_filter, 
        transaction_search, 
        transaction_from_date, 
        transaction_to_date, 
        transaction_financial_year,
        transaction_no_of_adm,
        transaction_paid_amt,
        transaction_pending_amt,

        selected_agent } = useSelector(selectAgencyDatas);
    const route_history = useHistory();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [visLoader, setVisLoader] = useState(null);

    useEffect(() => {
        const checkState = () => {
            if (JSON.stringify(selected_agent) === '{}') {
                route_history.push('/agency')
            }
        }
        checkState();
    }, [selected_agent, route_history])

    const delete_fx = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                delete_trasaction_action(route_history, dispatch, state.id, id);
            }
        })
    }

    const ScrollPos = (val) => {
        const { scrollHeight, scrollTop, clientHeight } = val.target;
        let bottom = scrollHeight - scrollTop === clientHeight
        if (bottom) {
            if (transaction_pagination) {
                let page_no_val = transaction_page + 1;
                fetch_transactions(route_history, dispatch, state.id, page_no_val, arr_transaction, transaction_search, transaction_filter, transaction_from_date, transaction_to_date, transaction_financial_year, false, true);
            }
        }
    }

    const fetch_studentData = (val) =>{
        setVisLoader(val)
        let api_url = `${STUDENT_BASE}?id=${val}`;
        const success_res = (res) =>{
            const {response} = res;
            setVisLoader(null)
            let obj = {...response, reDir : true}
            route_history.push('/students/detail', obj)
        }
        Api('get',api_url,'', route_history, success_res)
    }

    return (
        <div style={{ display: 'flex' }} className="app-main-list-layout">
            <div className="app-table-layout">
                <table className="app_table_main">
                    <tbody className="app_table_tbody">
                        <tr style={{ fontWeight: 'bold', fontSize: 12 }}>
                            <td style={{ width: 80, padding: 0 }}>Sl No</td>
                            <td style={{ width: 120, padding: 0 }}>Date</td>
                            <td style={{ width: 120, padding: 0 }}>Mode Pay</td>
                            <td style={{}}>Student Name</td>
                            <td style={{ width: 80, padding: 0 }}>Stud ID</td>
                            <td style={{ width: 120, padding: 0 }}>College</td>
                            <td style={{ width: 120, padding: 0 }}>Commission</td>
                            <td style={{ width: 120, padding: 0 }}>Paid Amount</td>
                            <td style={{ width: 100, padding: 0 }}>Action</td>
                        </tr>
                    </tbody>
                </table>

                <Scrollview onScroll={ScrollPos} style={{ height: '70vh', position: 'relative' }}>
                    {
                        !transaction_loader ? (
                            <>
                                {
                                    arr_transaction.length > 0 ? (
                                        <>
                                            <table className="app_table_main">
                                                <tbody className="app_table_tbody">
                                                    {
                                                        arr_transaction.map((item, key) => {
                                                            const { id, updated_at, mode_of_payment, student_name, student_id, college_name, commission, amount , financial_year} = item;
                                                            
                                                            return (
                                                                <tr key={key} className="app-table-row" 
                                                                    style={{ opacity : student_id ? item.active_students ? '1' : '.5' : '1',
                                                                     fontStyle : student_id ? item.active_students ? 'normal' : 'italic' : 'normal' }}
                                                                >
                                                                    <td style={{ width: 80, padding: 0 }}>TR{id}</td>
                                                                    <td style={{ width: 120, padding: 0 }}>{updated_at.split('T', 1)}</td>
                                                                    <td style={{ width: 120, padding: 0 }}>{mode_of_payment}</td>
                                                                    <td style={{}}>{student_name}</td>
                                                                    <td style={{ width: 80, padding: 0 }}>{student_id}</td>
                                                                    <td style={{ width: 120, padding: 0 }}>{college_name}</td>
                                                                    <td style={{ width: 120, padding: 0 }}>{commission}</td>
                                                                    <td style={{ width: 120, padding: 0 }}>{amount}</td>
                                                                    <td style={{ width: 100, padding: 0 }}>
                                                                        {
                                                                            amount ? (
                                                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                    <Edit
                                                                                        onClick={() => {
                                                                                            setAmount(amount);
                                                                                            setModeofpay(mode_of_payment)
                                                                                            setfinancialYear(financial_year)
                                                                                            setSelectedTransaction({ id });
                                                                                            setModal(true);
                                                                                        }}
                                                                                        style={{ fontSize: 16, marginRight: 5, cursor: 'pointer' }} />
                                                                                    <Delete onClick={() => delete_fx(id)} style={{ fontSize: 16, marginLeft: 5, cursor: 'pointer' }} />
                                                                                </div>
                                                                            ) : (<div onClick={() => fetch_studentData(item.student_id)} style={{ textDecoration: 'none', color: '#000' }}>
                                                                                {
                                                                                    visLoader===item.student_id ?
                                                                                        <CircularProgress size={16}  style={{ marginRight: 20, cursor: 'pointer' }}/>
                                                                                    :
                                                                                        <Visibility style={{ fontSize: 16, marginRight: 20, cursor: 'pointer' }} />
                                                                                }
                                                                            </div>)
                                                                        }

                                                                    </td>
                                                                </tr>
                                                            )

                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                transaction_list_loader && (
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <CircularProgress size={30} />
                                                    </div>
                                                )
                                            }
                                        </>
                                    ) : (
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                                            <p style={{ fontSize: 14, fontWeight: 'bold' }}>No Data Found</p>
                                        </div>
                                    )
                                }

                            </>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                                <CircularProgress size={26} />
                            </div>
                        )
                    }
                </Scrollview>
            </div>
            <div
                style={{
                    backgroundColor: '#CDCDCD',
                    marginLeft: 10,
                    height: 180,
                    width: 250,
                    borderRadius: 20,
                    fontSize: 12,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <p>Total No Students</p>
                <p style={{ fontWeight: 'bold', fontSize: 14 }}>{transaction_no_of_adm || 0} </p>

                <p style={{ marginTop: 20 }}>Total Paid Amount</p>
                <p style={{ fontWeight: 'bold', fontSize: 14 }}>{transaction_paid_amt || 0} </p>

                <p style={{ marginTop: 20 }}>Total Due Amount</p>
                <p style={{ fontWeight: 'bold', fontSize: 14 }}>{transaction_pending_amt || 0} </p>

            </div>
        </div>
    )
}

export default ListAgency
