import Api from '../../../store/api';
import { COMMISSION_BASE } from '../../../store/endpoint';
import { update_commission, update_commission_list_loader, update_commission_loader, update_single_commission } from '../store/slice';
import Swal from 'sweetalert2';


export const fetch_commission = (history, dispatch, page_no, prev_data, commission = 'all', search = '', loader = true, listLoader = false) => {
    let api_url = `${COMMISSION_BASE}?page=${page_no}&limit=20`
    if (search) {
        api_url = `${api_url}&key=${search}`
    }
    if (commission !== 'all') {
        api_url = `${api_url}&commission_type=${commission}`
    }

    dispatch(update_commission_loader({ loader: loader }));
    dispatch(update_commission_list_loader({ loader: listLoader }))

    Api('get', api_url, '', history).then(res => {
        const { response, statusCode } = res
        if (statusCode === 200) {

            let final_data = page_no > 1 ? prev_data.concat(response) : response
            let pagination_status;
            if (response.length < 20) {
                pagination_status = false;
            } else if (response.length === 20) {
                pagination_status = true;
            }
            dispatch(update_commission({
                arr_response: final_data,
                page_no,
                pagination: pagination_status,
                commission,
                search,
            }))

        } else {
            dispatch(update_commission_loader({ loader: false }));
        }
    })
}

export const update_commission_amount = (history, dispatch, post_data, commission_id, success, failed) => {
    let api_url = COMMISSION_BASE + commission_id + '/';
    // let api_url = `${BASE_MAIN}commission/crud/${commission_id}/`

    Api('put', api_url, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'commission Details Updated',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(update_single_commission({
                commission_id: commission_id,
                new_data: res.response
            }))
        }
    })
}