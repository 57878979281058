import React, { useEffect } from 'react';
import Navbar from '../components/navbar';
import ListCollege from '../components/list';
import { fetch_all_academic_yr, fetch_colleges } from '../store/action';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

function Home() {
    const route_history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        fetch_colleges(route_history, dispatch, 1, [], '', 20, true, null)
        fetch_all_academic_yr(route_history, dispatch)
    }, [route_history, dispatch])

    return (
        <div className="Home-dynamic-layout">
            <Navbar/>
            <ListCollege />
        </div>
    )
}

export default Home
