import AgencyApp from './agency'
import StaffApp from './staff'
import CollegeApp from './college'
import StudentsApp from './students'
import FeesApp from './fees'
import CommissionApp from './commission'
import FinanceApp from './finance'

export const routes = [
    {
        path: '/college',
        name: 'College',
        component: CollegeApp,
        exact: false
    },
    {
        path: '/students',
        name: 'Students',
        component: StudentsApp,
        exact: false
    },
    {
        path: '/fees',
        name: 'Fees',
        component: FeesApp,
        exact: false
    },
    {
        path: '/agency',
        name: 'Agency',
        component: AgencyApp,
        exact: true
    },
    {
        path: '/commission',
        name: 'Commission',
        component: CommissionApp,
        exact: false
    },
    {
        path: '/staff',
        name: 'Staff',
        component: StaffApp,
        exact: false
    },
    {
        path: '/finance',
        name: 'Finance',
        component: FinanceApp,
        exact: false
    }
]