import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import './style.css'
import { update_commission_amount, } from '../store/action';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core'



const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '95%',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function StaffModal({
    modalStatus,
    modalAction,
    data,
    StudentName,
    setStudentName,
    AgentName,
    setAgentName,
    Agency,
    setAgency,
    College,
    setCollege,
    Course,
    setCourse,
    Amount,
    setAmount,
    Batch,
    setBatch,
    StuFees,
    setStuFees,
    BatchId
}) {

    const classes = useStyles();

    let router_history = useHistory();
    let dispatch = useDispatch();
    const [buttonLoader, setButtonLoader] = useState(false);


    const handleClose = () => {
        modalAction(false)
        setButtonLoader(false);
        setCollege('')
        setCourse('')
        setAgentName('')
        setStudentName('')
        setAmount('')
        setBatch('')
        setAgency('')
        setStuFees('')

    };

    const button_fx = () => {
        setButtonLoader(true);

        const success = () => {
            handleClose()
            setButtonLoader(false);


        }
        const failed = () =>{
            setButtonLoader(false)
        }

        let postData = {

            college_name: College,
            course_name: Course,
            agent_name: AgentName,
            name: StudentName,
            agent_commission: Amount,
            batch: BatchId,
            agency: Agency,
            // student : StuFees,
        }

        if (data) {
            update_commission_amount(router_history, dispatch, postData, data.id, success, failed)
        }

    }




    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >

            <div className="app-modal-main">
                <h4 style={{ margin: 0, marginBottom: 20 }}>Update Commission</h4>

                <form className={classes.root} noValidate autoComplete="off">
                    <TextField
                        id="standard-basic"
                        label="Student Name"
                        value={StudentName}
                        disabled
                    />

                    <TextField
                        id="standard-basic"
                        label="Agent Name"
                        value={AgentName}
                        disabled
                    />

                    <TextField
                        id="standard-basic"
                        label="College"
                        value={College}
                        disabled
                    />

                    <TextField
                        id="standard-basic"
                        label="Course"
                        value={Course}
                        disabled
                    />

                    <TextField
                        id="standard-basic"
                        label="Batch"
                        value={Batch}
                        disabled
                    />

                    <TextField
                        id="standard-basic"
                        label="Student Toatal Fees"
                        value={StuFees}
                        disabled
                    />

                    <TextField
                        id="standard-basic"
                        label="Amount"
                        type="number"
                        value={Amount}
                        onChange={txt => setAmount(txt.target.value)}

                    />
                </form>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button
                        onClick={() => button_fx()}
                        className="submit-btn">
                        {buttonLoader ? (<CircularProgress size={20} color="inherit" />) : 'Update'}

                    </button>
                </div>
            </div>
        </Modal>
    );
}



export default StaffModal
