import React, { useEffect, useState } from 'react';
import Navbar from './components/navbar';
import ListTansaction from './components/list_transaction';
import FinanceModal from './components/finance_modal';
import { fetch_finance, fetch_category, fetch_sub_category, fetch_financial_years } from './store/action';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { fetch_all_agencies } from '../agency/store/action';

function App() {
    const [Modal, setModal] = useState(false);

    const [Amount, setAmount] = useState('');
    const [Description, setDescription] = useState('');
    const [Type, setType] = useState('');
    const [Category, setCategory] = useState('');
    const [SubCategory, setSubCategory] = useState('');
    const [ModePay, setModePay] = useState('');
    const [SelectedFinance, setSelectedFinance] = useState('');
    const [AgencyId, setAgencyId] = useState('')
    const [FinanceYrId, setFinanceYrId] = useState(null)
    const [search, setSearch] = useState('')

    const route_history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        fetch_finance(route_history, dispatch, 1);
        fetch_category(route_history, dispatch);
        fetch_sub_category(route_history, dispatch);
        fetch_financial_years(route_history, dispatch);
        fetch_all_agencies(route_history, dispatch)
        //fetch_finance_details_action(route_history, dispatch)
    }, [route_history, dispatch])

    return (
        <div className="app-dynamic-layout">
            <Navbar
                setSelectedFinance={setSelectedFinance}
                modalStatus={setModal}
                setAmount={setAmount}
                setDescription={setDescription}
                setType={setType}
                setCategory={setCategory}
                setModePay={setModePay}
                setSubCategory={setSubCategory}
                setAgencyId={setAgencyId}
            />
            <ListTansaction
                setSelectedFinance={setSelectedFinance}
                modalStatus={setModal}
                setAmount={setAmount}
                setDescription={setDescription}
                setType={setType}
                setCategory={setCategory}
                setModePay={setModePay}
                setSubCategory={setSubCategory}
                setAgencyId={setAgencyId}
                setFinanceYrId={setFinanceYrId}
                setSearch={setSearch}
            />
            <FinanceModal
                modalStatus={Modal}
                modalAction={setModal}
                data={SelectedFinance}
                Amount={Amount}
                setAmount={setAmount}
                Description={Description}
                setDescription={setDescription}
                Type={Type}
                setType={setType}
                Category={Category}
                setCategory={setCategory}
                ModePay={ModePay}
                setModePay={setModePay}
                SubCategory={SubCategory}
                setSubCategory={setSubCategory}
                setAgencyId={setAgencyId}
                AgencyId={AgencyId}
                FinanceYrId={FinanceYrId}
                setFinanceYrId={setFinanceYrId}
                search={search}
                setSearch={setSearch}
            />

        </div>
    )
}

export default App
