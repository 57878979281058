import React from 'react'
import Navbar from '../components/navbar_detail'
import Tabs from '../components/detail_tabs'

function Detail() {
    return (
        <div className="Home-dynamic-layout">
            <Navbar />
            <div style={{ padding: '0px 40px' }}>
                <Tabs />
            </div>
        </div>
    )
}

export default Detail
