import { createSlice } from '@reduxjs/toolkit';

export const agencySlice = createSlice({
    name: 'agency',
    initialState: {
        // Agency informations
        arr_agencies: [],
        agency_page: 1,
        agency_filter: 'all',
        agency_financial_yr: null,
        agency_search: '',
        agency_pagination: true,
        agency_loader: false,
        agency_list_loader: false,

        // Agency Transaction informations
        arr_transaction: [],
        transaction_page: 1,
        transaction_pagination: true,
        transaction_filter: 'all',
        transaction_search: '',
        transaction_from_date: '',
        transaction_to_date: '',
        transaction_loader: false,
        transaction_list_loader: false,
        transaction_financial_year:'all',

        transaction_no_of_adm: null,
        transaction_paid_amt: null,
        transaction_pending_amt: null,

        all_agencts_list: [],
        selected_agent: {},
    },
    reducers: {
        // Agency informations
        update_agencies: (state, action) => {
            const { arr_response, page_no, pagination, filter, search, financialYr } = action.payload;
            state.arr_agencies = arr_response;
            state.agency_page = page_no;
            state.agency_pagination = pagination;
            state.agency_filter = filter;
            state.agency_financial_yr = financialYr;
            state.agency_search = search;
            state.agency_loader = false;
            state.agency_list_loader = false;
        },
        update_agency_loader: (state, action) => {
            const { loader } = action.payload;
            state.agency_loader = loader;
        },
        update_agency_list_loader: (state, action) => {
            const { loader } = action.payload;
            state.agency_list_loader = loader;
        },
        update_agency_pagination: (state, action) => {
            const { status } = action.payload;
            state.agency_pagination = status;
        },
        update_agency_filter: (state, action) => {
            const { filter } = action.payload;
            state.agency_filter = filter;
        },

        add_single_agent: (state, action) => {
            const { new_data } = action.payload;
            state.arr_agencies.unshift(new_data);
        },

        remove_single_agent: (state, action) => {
            const { agent_id } = action.payload;
            let latest_list = state.arr_agencies.filter(item => item.id !== agent_id);
            state.arr_agencies = latest_list;
        },

        update_single_agent: (state, action) => {
            const { agent_id, new_data } = action.payload;
            let array_item = state.arr_agencies.find(item => item.id === agent_id);
            let arra_item_index = state.arr_agencies.indexOf(array_item);
            state.arr_agencies[arra_item_index] = new_data;
        },

        // Agency Transaction informations
        update_transactions: (state, action) => {
            const { arr_response, page_no, pagination, filter, search, filter_from, filter_to ,year, admn_no, paid_amt, due_amt} = action.payload
            state.arr_transaction = arr_response;
            state.transaction_no_of_adm = admn_no;

            state.transaction_paid_amt = paid_amt;
            state.transaction_pending_amt = due_amt;
            state.transaction_page = page_no;
            state.transaction_pagination = pagination;
            state.transaction_filter = filter;
            state.transaction_search = search;
            state.transaction_from_date = filter_from;
            state.transaction_to_date = filter_to;
            state.transaction_financial_year= year;

            state.transaction_loader = false;
            state.transaction_list_loader = false;
        },
        update_transaction_loader: (state, action) => {
            const { loader } = action.payload;
            state.transaction_loader = loader;
        },
        update_transaction_list_loader: (state, action) => {
            const { loader } = action.payload;
            state.transaction_list_loader = loader;
        },
        add_single_transaction: (state, action) => {
            const { new_data } = action.payload;
            state.arr_transaction.unshift(new_data);
            let amount = new_data.amount;
            let total_paid = Number(state.transaction_paid_amt) + Number(amount);
            let due_amount = state.transaction_pending_amt - amount;
            state.transaction_paid_amt = Number(total_paid);
            state.transaction_pending_amt = due_amount;

            // console.log('jnjnjnjjjn',amount);
            // console.log('hoiijhfu',total_paid);
            // console.log('jngjngrjrg',due_amount);



        },
        update_single_transaction: (state, action) => {
            const { transaction_id, new_data } = action.payload;
            let array_item = state.arr_transaction.find(item => item.id === transaction_id);
            let arra_item_index = state.arr_transaction.indexOf(array_item);
            let oldAmtData = Number(state.transaction_paid_amt) - Number(state.arr_transaction[arra_item_index].amount)
            let oldDueAmtData = Number(state.transaction_pending_amt) + Number(state.arr_transaction[arra_item_index].amount)  
            state.arr_transaction[arra_item_index] = new_data;
            state.transaction_paid_amt = Number(oldAmtData) + Number(new_data.amount);
            state.transaction_pending_amt = Number(oldDueAmtData) - Number(new_data.amount);
        },
        remove_single_transaction: (state, action) => {
            const { transaction_id } = action.payload;
            let array_item = state.arr_transaction.find(item => item.id === transaction_id);
            let arra_item_index = state.arr_transaction.indexOf(array_item);
            let oldAmtData = Number(state.transaction_paid_amt) - Number(state.arr_transaction[arra_item_index].amount)
            let oldDueAmtData = Number(state.transaction_pending_amt) + Number(state.arr_transaction[arra_item_index].amount)  
            
            let latest_list = state.arr_transaction.filter(item => item.id !== transaction_id);
            state.arr_transaction = latest_list;
            state.transaction_paid_amt = oldAmtData;
            state.transaction_pending_amt = oldDueAmtData;
        
        },
        update_selected_agent: (state, action) => {
            const { arr_response } = action.payload;
            state.arr_transaction = [];
            state.selected_agent = arr_response;
        },
        update_agency_list: (state, action) => {
            const { arr_response } = action.payload;
            state.all_agencts_list = arr_response
        }

    }
})

export const {
    update_agencies,
    update_agency_loader,
    update_agency_list_loader,
    update_agency_pagination,
    update_agency_filter,
    add_single_agent,
    remove_single_agent,

    update_single_agent,
    update_transactions,
    update_transaction_loader,
    update_transaction_list_loader,
    add_single_transaction,
    update_single_transaction,
    remove_single_transaction,
    update_selected_agent,

    update_agency_list
} = agencySlice.actions

export const selectAgencyDatas = state => state.agency;

export default agencySlice.reducer;