import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal, } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { create_transaction, update_transaction_action } from '../store/action';
import MenuItem from '@material-ui/core/MenuItem';
import { CircularProgress } from '@material-ui/core'


// import { create_finance, update_finance } from '../store/action';



const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '95%',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function TransactionModal({
    modalStatus,
    modalAction,
    data,
    Amount,
    setAmount,
    Modeofpay,
    setModeofpay,
    financialYear,
    setfinancialYear,
    financialYrList,
}) {

    const classes = useStyles();
    const route_history = useHistory();
    const dispatch = useDispatch();


    const { state } = useLocation();
    const [buttonLoader, setButtonLoader] = useState(false);

    // console.log('statettetete',state)
    // console.log('dattttatttata',data)

    const handleClose = () => {
        modalAction(false)
        setAmount('')
        setModeofpay('')
    };

    const buttonClick = () => {
        setButtonLoader(true);

        let postData = {
            amount: Amount,
            mode_of_payment: Modeofpay,
            financial_year: financialYear,
            agency: state.id

        }
        const success = () => {
            handleClose()
            setButtonLoader(false);

        };
        const failed = () => {
            setButtonLoader(false)
        }

        if (data) {
            update_transaction_action(route_history, dispatch, state.id, data.id, postData, success, failed)
        } else {
            create_transaction(route_history, dispatch, state.id, postData, success, failed)
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >

            <div className="app-modal-main">
                <h4 style={{ margin: 0, marginBottom: 20, textTransform: 'capitalize' }}>{data ? 'Update' : 'Insert'} Record {state && state.id ? 'of  ' + state.consultant_name : ''}</h4>

                <form className={classes.root} noValidate autoComplete="off">
                    <TextField
                        id="standard-basic"
                        label="Amount"
                        type="number"
                        value={Amount}
                        onChange={txt => setAmount(txt.target.value)}
                    />

                    <TextField
                        id="standard-basic"
                        select
                        label="Mode of pay"
                        value={Modeofpay}
                        onChange={txt => setModeofpay(txt.target.value)}
                    >

                        <MenuItem value={''}>Choose Mode</MenuItem>
                        <MenuItem value="Cash">Cash</MenuItem>
                        <MenuItem value="UPI">UPI</MenuItem>
                        <MenuItem value="Cheque">Cheque</MenuItem>
                        <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                    </TextField>


                    <TextField
                        id="standard-basic"
                        select
                        label="Financial Year"
                        value={financialYear}

                        onChange={txt => {
                            let year = txt.target.value;
                            setfinancialYear(year)
                            // transaction_fetching_filters(transaction_search, transaction_filter, transaction_from_date, transaction_to_date ,year)

                        }}
                    >

                        <MenuItem value=''>Choose Financial Year</MenuItem>
                        {
                            financialYrList && financialYrList.map((item, key) => {
                                const { id, year } = item
                                return (
                                    <MenuItem key={key} value={id}>{year}</MenuItem>
                                )
                            })
                        }
                    </TextField>

                </form>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button onClick={buttonClick}
                        className={buttonLoader ? "submit-btn disabled-btn" : "submit-btn"} >
                        {buttonLoader ? (<CircularProgress size={20} color="inherit" />) : (data ? 'Update' : 'Create')}

                    </button>
                </div>

            </div>
        </Modal>
    );
}



export default TransactionModal
