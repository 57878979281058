import Api from '../../../store/api'
import { AGENCY_BASE, AGENCY_TRANSACTION_PRINT, COLLEGE_BASE } from '../../../store/endpoint';
import { update_agencies, 
    add_single_agent, 
    update_single_agent, 
    remove_single_agent, 
    update_transactions, 
    add_single_transaction, 
    update_single_transaction, 
    remove_single_transaction, 
    update_agency_loader, update_transaction_loader, update_agency_list_loader, update_transaction_list_loader, update_agency_list } from '../store/slice';
import { update_colleges } from '../../college/store/slice';
import Swal from 'sweetalert2';
import { FileDownloader } from '../../../store/file_downloader';
// import dayjs from 'dayjs';


export const fetch_colleges = (history, dispatch, collegeAcdYr = null) => { //FOR FILTER
    
    let api_url = collegeAcdYr!==null ? `${COLLEGE_BASE}?page=1&year=${collegeAcdYr}&limit=60`
    : `${COLLEGE_BASE}?page=1&limit=60`
    Api('get', api_url, '', history).then(res => {
        const { response } = res
        dispatch(update_colleges({
            arr_response: response,
            academic_yr : collegeAcdYr
        }))
    })
}

export const fetch_agencies = (history, dispatch, page_no, prev_data = [], filter = 'all', search = '', loader = true, listLoader = false, financialYr=null) => {
    let api_url = `${AGENCY_BASE}?page=${page_no}&limit=20`
    if (search) {
        api_url = `${api_url}&key=${search}`
    }
    if (filter !== 'all') {
        api_url = `${api_url}&college=${filter}`
    }
    if(financialYr!==null){
        api_url = `${api_url}&financial_year=${financialYr}`
    }
    dispatch(update_agency_loader({ loader: loader }));
    dispatch(update_agency_list_loader({ loader: listLoader }));
    Api('get', api_url, '', history).then(res => {
        const { response, statusCode } = res
        if (statusCode === 200) {
            let final_data = page_no > 1 ? prev_data.concat(response) : response
            let pagination_status;
            if (response.length < 20) {
                pagination_status = false;
            } else if (response.length === 20) {
                pagination_status = true;
            }
            dispatch(update_agencies({
                arr_response: final_data,
                page_no,
                pagination: pagination_status,
                filter,
                financialYr,
                search
            }))
        } else {
            dispatch(update_agency_loader({ loader: false }));
        }

    })
}


export const create_agency = (history, dispatch, post_data, success, failed) => {
    Api('post', AGENCY_BASE, post_data, history, success).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Agent Created Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(add_single_agent({
                new_data: res.response
            }));
        } else {
            if(failed)
                failed();
        }

    })
}

export const update_agency = (history, dispatch, post_data, agent_id, success, failed) => {
    let api_url = AGENCY_BASE + agent_id + '/';
    Api('put', api_url, post_data, history, success).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Agent Details Updated',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(update_single_agent({
                agent_id: agent_id,
                new_data: res.response
            }))
        } else {
            if(failed)
                failed();
        }
    })
}

export const delete_agency = (history, dispatch, agent_id) => {
    let api_url = AGENCY_BASE + agent_id + '/';
    Api('delete', api_url, '', history).then(res => {
        if (res.statusCode === 200) {
            Swal.fire(
                'Deleted!',
                'Agent deleted!',
                'success'
            )
            dispatch(remove_single_agent({
                agent_id: agent_id,
            }))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.error_app,
            })
        }
    })
}


// Agency Transaction informations

export const fetch_transactions = (history, dispatch, agent_id, page_no, prev_data = [], search = '', filter = 'all', filter_from = '', filter_to = '', year='', loader = true, listLoader = false) => {
    let api_url = `${AGENCY_BASE}${agent_id}/detail/?page=${page_no}&limit=20`
    if (search) {
        api_url = `${api_url}&key=${search}`
    }
    if (filter !== 'all') {
        api_url = `${api_url}&college=${filter}`
    }
    if (year !== 'all') {
        api_url = `${api_url}&year=${year}`
    }
    if (filter_from && filter_to) {
        api_url = `${api_url}&from=${filter_from}&to=${filter_to}`
    }
    dispatch(update_transaction_loader({ loader: loader }));
    dispatch(update_transaction_list_loader({ loader: listLoader }));
    Api('get', api_url, '', history).then(res => {
        console.log(res)
        const { response, statusCode } = res
        if (statusCode === 200) {
            let final_data = page_no > 1 ? prev_data.concat(response.resut) : response.resut
            let pagination_status;
            if (response.resut.length < 20) {
                pagination_status = false;
            } else if (response.resut.length === 20) {
                pagination_status = true;
            }
            dispatch(update_transactions({
                arr_response: final_data,
                admn_no: response.student_count,
                paid_amt: response.paid_amount,
                due_amt: response.due_amount,
                page_no,
                pagination: pagination_status,
                search,
                filter,
                filter_from,
                filter_to,
                year

            }))
        } else {
            dispatch(update_transaction_loader({ loader: false }));
        }
    })
}


export const create_transaction = (history, dispatch, agent_id, post_data, success, failed) => {
    let api_url = `${AGENCY_BASE}${agent_id}/detail/`;
    Api('post', api_url, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Transaction Created Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(add_single_transaction({
                new_data: res.response
            }));
        }else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })
        }

    })
}

export const update_transaction_action = (history, dispatch, agent_id, transaction_id, post_data, success, failed) => {
    let api_url = `${AGENCY_BASE}${agent_id}/detail/${transaction_id}/`;
    Api('put', api_url, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Agent Details Updated',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(update_single_transaction({
                transaction_id: transaction_id,
                new_data: res.response
            }))
        }else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })
        }
    })
}

export const delete_trasaction_action = (history, dispatch, agent_id, transaction_id, success, failed) => {
    let api_url = `${AGENCY_BASE}${agent_id}/detail/${transaction_id}/`;
    Api('delete', api_url, '', history).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Transaction Deleted Successfully!',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(remove_single_transaction({
                transaction_id: transaction_id,
            }))
        } else {
            if(failed)
                failed();
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.error_app,
            })
        }
    })
}

export const print_transactions = (history, agent_id, filter = 'all', filter_from = '', filter_to = '', success, failed) => {
    let api_url = `${AGENCY_TRANSACTION_PRINT}${agent_id}/?`
    if (filter !== 'all') {
        api_url = `${api_url}&college=${filter}`
    }
    if (filter_from && filter_to) {
        api_url = `${api_url}&from=${filter_from}&to=${filter_to}`
    }
    Api('file-download', api_url, '', history).then(res => {
        success();
        const { response, statusCode } = res
        if (statusCode === 200) {
            FileDownloader(response, 'agent_transaction_');
        } else {
            if(failed)
                failed();
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.error_app,
            })
        }
    })
}

export const fetch_all_agencies = (history, dispatch) =>{
    let api_url = `${AGENCY_BASE}?limit=10000`
    Api('get', api_url, '', history).then(res => {
        console.log(res)
        const { response } = res

        dispatch(update_agency_list({
            arr_response: response
        }))
    })
}