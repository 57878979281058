import React, { useState } from 'react';
import { Delete, Edit } from '@material-ui/icons';
import Scrollview from 'react-custom-scrollbars';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { batch_status_change, delete_college_batch, fetch_college_batches } from '../store/action';
import { selectCollegeDatas } from '../store/slice';
import BatchModal from '../components/batch_modal';
import { CircularProgress, FormControl, MenuItem, Select } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        //   margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    resize: {
        fontSize: 14,
        fontWeight: 700,
        color: 'black'
    }
}));

function TabBatchList() {

    const { arr_college_batch, college_batch_loader, 
        college_batch_page, college_batch_pagination, 
        college_batch_search, arr_college_course, college_batch_course_filter, college_acd_yr } = useSelector(selectCollegeDatas);
    const classes = useStyles();
    const route_history = useHistory();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const [Modal, setModal] = useState(false);
    const [SelectedBatch, setSelectedBatch] = useState({});

    const [BatchName, setBatchName] = useState('');
    const [Course, setCourse] = useState('');
    const [BatchFrom, setBatchFrom] = useState('');
    const [BatchTo, setBatchTo] = useState('');

    const delete_fx = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {

            if (result.isConfirmed) {
                delete_college_batch(route_history, dispatch, id);
            }
        })
    }

    const ScrollPos = (val) => {
        const { scrollHeight, scrollTop, clientHeight } = val.target;
        let bottom = scrollHeight - scrollTop === clientHeight
        if (bottom) {
            if (college_batch_pagination) {
                let page_no_val = college_batch_page + 1;
                fetch_college_batches(route_history, dispatch, state.college_id, page_no_val, arr_college_batch, college_batch_search, false, college_batch_course_filter, college_acd_yr)
            }
        }
    }

    // const statusChangeFx = () => {

    // }

    return (
        <div style={{ padding: '0px', margin: 0, height: '72vh' }} className="app-main-list-layout">
            <div className="app-table-layout">

                <table className="app_table_main">
                    <tbody className="app_table_tbody">
                        <tr style={{ fontWeight: 'bold', fontSize: 12 }}>
                            <td style={{ width: 80 }}>Batch Id</td>
                            <td style={{ width: 150 }}>Batch Name</td>
                            <td style={{ width: 150 }}>
                            <FormControl className={classes.formControl}>
                            <Select
                            disableUnderline
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            SelectDisplayProps={{ fontSize: 14, fontWeight: 700 }}
                            value={college_batch_course_filter===null ? 'all' : college_batch_course_filter}
                            className={classes.resize}
                            onChange={txt => {
                                fetch_college_batches(route_history, dispatch, state.college_id, 1, arr_college_batch, college_batch_search, false, txt.target.value==='all' ? null : txt.target.value, college_acd_yr)
                                
                            }}
                        >
                            <MenuItem value={'all'}>Course Filter - All</MenuItem>
                            {
                                arr_college_course && arr_college_course.map((item, k) => {
                                    const { id, name } = item;
                                    return <MenuItem key={k} value={id}>{name}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                            </td>
                            <td style={{ width: 150 }}>From Date</td>
                            <td style={{ width: 150 }}>To Date</td>
                            <td style={{ width: 200 }}>Manage</td>
                        </tr>
                    </tbody>
                </table>

                <Scrollview
                    onScroll={e => ScrollPos(e)}
                    style={{ height: '68vh', position: 'relative' }}>

                    {!college_batch_loader ? (
                        <>
                            {arr_college_batch.length > 0 ? (
                                <table className="app_table_main">
                                    <tbody className="app_table_tbody">
                                        {
                                            arr_college_batch.map((item, key) => {
                                                const { id, name, course_name, course, from_date, to_date, batch_disabled_status, user_updated_status } = item;
                                                let disabled_btn = true;

                                                if (batch_disabled_status && user_updated_status) {
                                                    disabled_btn = false;
                                                }
                                                return (
                                                    <tr key={key} className="app-table-row">
                                                        <td style={{ width: 80 }}>BT{id}</td>
                                                        <td style={{ width: 150 }}>{name}</td>
                                                        <td style={{ width: 150 }}>{course_name}</td>
                                                        <td style={{ width: 150 }}>{from_date}</td>
                                                        <td style={{ width: 150 }}>{to_date}</td>
                                                        <td style={{ width: 200 }}>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                {/* <Visibility 
                                                                // onClick={() => modalStatus(true)} 
                                                            style={{ fontSize: 16, marginRight: 20, cursor: 'pointer' }} /> */}
                                                                <Edit
                                                                    onClick={() => {
                                                                        setSelectedBatch(item);
                                                                        setBatchName(name);
                                                                        setCourse(course);
                                                                        setBatchFrom(from_date);
                                                                        setBatchTo(to_date);
                                                                        setModal(true);
                                                                    }}
                                                                    style={{ fontSize: 16, marginRight: 20, cursor: 'pointer' }}
                                                                />
                                                                <Delete onClick={() => delete_fx(id)} style={{ fontSize: 16, marginRight: 20, cursor: 'pointer' }} />
                                                                <>
                                                                    {
                                                                        batch_disabled_status & !user_updated_status ? (
                                                                            <button disabled>Disable</button>
                                                                        ) : (
                                                                            <>
                                                                                {
                                                                                    disabled_btn ? (
                                                                                        <button onClick={() => batch_status_change(route_history, dispatch, id)}>Disable</button>
                                                                                    ) : (
                                                                                        <button onClick={() => batch_status_change(route_history, dispatch, id)}>Enable</button>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                                    <p style={{ fontSize: 14, fontWeight: 'bold' }}>No Data Found</p>
                                </div>
                            )}
                        </>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                            <CircularProgress size={26} />
                        </div>
                    )}
                </Scrollview>
            </div>

            <BatchModal
                modalStatus={Modal}
                modalAction={setModal}
                data={SelectedBatch}
                BatchName={BatchName}
                setBatchName={setBatchName}
                Course={Course}
                setCourse={setCourse}
                BatchFrom={BatchFrom}
                setBatchFrom={setBatchFrom}
                BatchTo={BatchTo}
                setBatchTo={setBatchTo}
            />
        </div>
    )
}

export default TabBatchList
