import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import Home from './screens/home'
import Detail from './screens/details'

function App() {
    const { path } = useRouteMatch()
    return (
        <Switch>
            <Route path={path + '/detail'} name="Detail" component={Detail} />
            <Route path={path} name="home" component={Home} />
        </Switch>
    )
}

export default App
